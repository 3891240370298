import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  AuthLogin,
  AuthLoginPost,
  AuthLoginResponse,
} from '../../apiTypes/auth';
import { AuthState } from './auth.types';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

const initialErrorsState = {
  loginError: null,
};

const initialState: AuthState = {
  access: '',
  refresh: '',
  isLoading: false,
  isStaff: false,
  is_completed: null,
  ...initialErrorsState,
};

const createAsyncThunk = createEnhancedThunk('auth');

export const login = createAsyncThunk<AuthLogin, AuthLoginPost>('login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post<AuthLoginPost, AuthLoginResponse>(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, {
        email,
        password,
      });
      if (!response.data.data.is_staff) {
        return thunkAPI.rejectWithValue({
          messages: { error: ['Check your credentials'] },
        });
      }
      const cookies = new Cookies();
      cookies.set('access', response.data.data.access, { path: '/' });
      cookies.set('refresh', response.data.data.refresh, { path: '/' });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const authResetErrors = createAction('RESET_ERRORS');
export const authLogOut = createAction('AUTH_LOGOUT');

export default createReducer(initialState, (builder) => {
  builder.addCase(login.fulfilled, (state, { payload }) => ({
    ...state,
    access: payload.data.access,
    refresh: payload.data.refresh,
    isStaff: payload.data.is_staff,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(login.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(login.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    loginError: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(authLogOut.type, (state) => {
    const cookies = new Cookies();
    cookies.remove('access', { path: '/' });
    cookies.remove('refresh', { path: '/' });
    return ({
      ...state,
      ...initialState,
    });
  });
});

export const selectLoginError = createSelector(
  (state: RootState) => state.auth.loginError,
  (id) => id,
);

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.auth.isLoading,
  (id) => id,
);

export const selectIsCompleted = createSelector(
  (state: RootState) => state.auth.is_completed,
  (id) => id,
);

export const selectTokens = createSelector(
  (state: RootState) => ({
    access_token: state.auth.access,
    refresh_token: state.auth.refresh,
  }),
  (id) => id,
);
