import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  BankExportStatusesSellerSheetResponse,
  EditSellerSheetResponse,
  EditSellerSheetType,
  GetSellerSheetResponse,
  GetSellerSheetsResponse,
  GetSellerSheetsType,
  GetSellerSheetType,
  PaymentMethodsSellerSheetResponse,
  PaymentStatusesSellerSheetResponse,
  PostSellerSheetResponse,
  PostSellerSheetType,
  SellerSheetBankExportFileResponse,
  SellerSheetBankExportPreviewResponse,
  SellerSheetsPatchStateType,
  SellerSheetsStateType,
  SellerSheetState,
} from './sellerSheet.types';
import { paymentContent, tablePaginationParams } from '../../../staticData';
import {
  dispatchErrorMessage,
  errorMessageAction,
  successMessageAction,
} from '../ui/ui';
import { OptionsType } from '../../../components/molecules/Filters/Filters';
import { handleDownloadFile, handleDownloadLink } from '../../../utilities';
import readablePaymentMethod from '../paymentMethods/utils';
import { ResponseFile } from '../../apiTypes/shared/response';

const initialErrorsState = {
  summaryErrors: null,
  errors: null,
};

const initialState: SellerSheetState = {
  result: [],
  bankExport: {
    message: null,
    bank_export_data: [],
    isLoading: false,
  },
  sellerSheet: {
    id: undefined,
    auction: undefined,
    user: undefined,
    reserve_fee: null,
    entry_fee: null,
    invoice: null,
    notes: null,
    vat: null,
    sellers_commission: null,
    sellers_discount: null,
    sellers_discount_description: null,
    sellers_commission_limit: null,
    payment_method_details: undefined,
    payment_currency: 'GBP',
  },
  summary: null,
  isLoading: false,
  isSummaryLoading: false,
  paymentMethodsOptions: [],
  paymentStatusesOptions: [],
  bankExportStatusesOptions: [],
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('sellerSheet');

export const getSellerSheetsAction = createAsyncThunk<
  GetSellerSheetsType,
  PaginationType & any
>('getSellerSheets', async ({ page, perPage, params }, thunkAPI) => {
  try {
    const response = await thunkAPI.extra.get<
      PaginationType,
      GetSellerSheetsResponse
    >('/api/seller_sheets/', {
      params: {
        page: page + 1,
        page_size: perPage,
        ...params,
      },
    });
    return response.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const getSellerSheetAction = createAsyncThunk<
  GetSellerSheetType,
  { id: number }
>('getSellerSheet', async ({ id }, thunkAPI) => {
  try {
    const response = await thunkAPI.extra.get<
      { id: number },
      GetSellerSheetResponse
    >(`/api/seller_sheets/${id}/`);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});
export const getSellerSheetSummaryAction = createAsyncThunk<
  GetSellerSheetType,
  { id: number }
>('getSellerSheetSummaryAction', async ({ id }, thunkAPI) => {
  try {
    const response = await thunkAPI.extra.get<
      { id: number },
      GetSellerSheetResponse
    >(`/api/seller_sheets/${id}/summary/`);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const createSellerSheetAction = createAsyncThunk<
  PostSellerSheetType,
  SellerSheetsStateType
>(
  'createSellerSheet',
  async (
    {
      auction,
      user,
      reserve_fee,
      entry_fee,
      vat,
      sellers_commission,
      sellers_commission_limit,
      sellers_discount,
      sellers_discount_description,
      payment_status,
      bulk_bank_export,
      group_lot_entry_fee,
      notes,
    },
    thunkAPI,
  ) => {
    try {
      const response = await thunkAPI.extra.post<
        SellerSheetsStateType,
        PostSellerSheetResponse
      >('/api/seller_sheets/', {
        auction,
        user,
        reserve_fee,
        entry_fee,
        vat,
        sellers_commission,
        sellers_commission_limit,
        sellers_discount,
        bulk_bank_export,
        sellers_discount_description,
        group_lot_entry_fee,
        payment_status,
        notes,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const editSellerSheetAction = createAsyncThunk<
  EditSellerSheetType,
  SellerSheetsPatchStateType
>(
  'editSellerSheets',
  async (
    {
      id,
      auction,
      user,
      reserve_fee,
      entry_fee,
      vat,
      sellers_commission,
      sellers_commission_limit,
      sellers_discount,
      sellers_discount_description,
      payment_status,
      bulk_bank_export,
      group_lot_entry_fee,
      notes,
    },
    thunkAPI,
  ) => {
    try {
      const response = await thunkAPI.extra.patch<
        SellerSheetsStateType,
        EditSellerSheetResponse
      >(`/api/seller_sheets/${id}/`, {
        auction,
        user,
        reserve_fee,
        entry_fee,
        vat,
        sellers_commission,
        sellers_commission_limit,
        sellers_discount,
        bulk_bank_export,
        sellers_discount_description,
        payment_status,
        group_lot_entry_fee,
        notes,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const deleteSellerSheetAction = createAsyncThunk<null, { id?: number }>(
  'deleteSellerSheet',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/seller_sheets/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const bulkDeleteSellerSheetAction = createAsyncThunk<null, any>(
  'bulkDeleteSellerSheetAction',
  async (ids, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete('/api/seller_sheets/', {
        headers: {
          'X-BULK-OPERATION': 'true',
        },
        params: {
          id: ids.keys,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const getSellerSheetPaymentMethods = createAsyncThunk<
  PaymentMethodsSellerSheetResponse['data'],
  void
>('getSellerSheetPaymentMethods', async (_, thunkAPI) => {
  try {
    const res = await thunkAPI.extra.get<PaymentMethodsSellerSheetResponse>(
      `/api/seller_sheets/payment_method_list/`,
    );
    return res.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export const getSellerSheetPaymentStatuses = createAsyncThunk<
  PaymentStatusesSellerSheetResponse['data'],
  void
>('getSellerSheetPaymentStatuses', async (_, thunkAPI) => {
  try {
    const res = await thunkAPI.extra.get<PaymentStatusesSellerSheetResponse>(
      `/api/seller_sheets/payment_status_list/`,
    );
    return res.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export const submitSellerSheetDraft = createAsyncThunk<
  GetSellerSheetType,
  number
>('submitSellerSheetDraft', async (id, thunkAPI) => {
  try {
    const res = await thunkAPI.extra.post<void, GetSellerSheetResponse>(
      `/api/seller_sheets/${id}/approve/`,
    );
    thunkAPI.dispatch(
      successMessageAction('Seller Sheet has been approved successfully'),
    );
    return res.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export const postPaymentInfoRequest = createAsyncThunk<void, number[]>(
  'postPaymentInfoRequest',
  async (userdIds, thunkAPI) => {
    try {
      await thunkAPI.extra.post<void>(`/api/users/send_payment_reminders/`, {
        objects: userdIds,
      });
      thunkAPI.dispatch(successMessageAction('Request has been sent'));
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction('Request sending failure'));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const downloadSellerSheetInvoice = createAsyncThunk<void, number>(
  'downloadSellerSheetInvoice',
  async (sellerSheetId, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<ResponseFile>(
        `/api/seller_sheets/${sellerSheetId}/get_pdf/`,
      );
      handleDownloadFile({ b64: res.data.data.file, filename: res.data.data.filename, mime_type: res.data.data.mime_type });
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const getSellerSheetBankExportFile = createAsyncThunk<void, void>(
  'getSellerSheetBankExportFile',
  async (_, thunkAPI) => {
    try {
      const response =
        await thunkAPI.extra.get<SellerSheetBankExportFileResponse>(
          `/api/seller_sheets/bank_export/`,
          {
            params: { preview: false },
          },
        );
      console.log(response);
      if ('message' in response.data.data) {
        thunkAPI.dispatch(errorMessageAction(response.data.message));
      } else {
        handleDownloadLink(`data:text/plain;base64,${response.data.data.file}`, response.data.data.filename);
      }

      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const getSellerSheetBankExportPreview = createAsyncThunk<
  SellerSheetBankExportPreviewResponse['data'],
  void
>('getSellerSheetBankExportPreview', async (_, thunkAPI) => {
  try {
    const response =
      await thunkAPI.extra.get<SellerSheetBankExportPreviewResponse>(
        `/api/seller_sheets/bank_export/`,
        { params: { preview: true } },
      );
    return response.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const getSellerSheetBanxExportStatuses = createAsyncThunk<
  BankExportStatusesSellerSheetResponse['data'],
  void
>('getSellerSheetBanxExportStatuses', async (_, thunkAPI) => {
  try {
    const res = await thunkAPI.extra.get<BankExportStatusesSellerSheetResponse>(
      `/api/seller_sheets/bank_export_status_list/`,
    );
    return res.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export const sendSellerSheetRecord = createAsyncThunk<void, number>(
  'sendSellerSheetRecord',
  async (id, thunkAPI) => {
    try {
      await thunkAPI.extra.get<void>(`/api/seller_sheets/${id}/send_record/`);
      thunkAPI.dispatch(
        successMessageAction('Email with results has been sent.'),
      );
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const sendSellerSheetRecordBulk = createAsyncThunk<void, number[]>(
  'sendSellerSheetRecordBulk',
  async (ids, thunkAPI) => {
    try {
      const body = { objects: ids };
      await thunkAPI.extra.post<void>(`/api/seller_sheets/list_send_record/`, body);
      thunkAPI.dispatch(
        successMessageAction('Emails with results have been sent.'),
      );
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const sendAuctionListing = createAsyncThunk<void, number>(
  'sendAuctionListing',
  async (id, thunkAPI) => {
    try {
      await thunkAPI.extra.get<void>(`/api/seller_sheets/${id}/send_auction_listing/`);
      thunkAPI.dispatch(
        successMessageAction('Email with auction listing has been sent.'),
      );
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const sendAuctionListingBulk = createAsyncThunk<void, number[]>(
  'sendAuctionListingBulk',
  async (ids, thunkAPI) => {
    try {
      const body = { objects: ids };
      await thunkAPI.extra.post<void>(`/api/seller_sheets/list_send_auction_listing/`, body);
      thunkAPI.dispatch(
        successMessageAction('Emails with auction listing have been sent.'),
      );
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const resetSellerSheetForm = createAction('resetSellerSheetForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(
    getSellerSheetsAction.fulfilled,
    (state, { payload, meta }) => ({
      ...state,
      ...initialErrorsState,
      result: payload.data.results,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      count: payload.data.count,
      isLoading: false,
    }),
  );
  builder.addCase(getSellerSheetsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getSellerSheetsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getSellerSheetAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    sellerSheet: payload.data,
    isLoading: false,
  }));
  builder.addCase(getSellerSheetAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getSellerSheetAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(
    getSellerSheetSummaryAction.fulfilled,
    (state, { payload }) => ({
      ...state,
      ...initialErrorsState,
      summary: payload.data,
      isSummaryLoading: false,
    }),
  );
  builder.addCase(getSellerSheetSummaryAction.pending, (state) => ({
    ...state,
    isSummaryLoading: true,
  }));
  builder.addCase(getSellerSheetSummaryAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    summaryErrors: action?.payload?.messages,
    isSummaryLoading: false,
  }));

  builder.addCase(createSellerSheetAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    auction: payload.data,
    isLoading: false,
  }));
  builder.addCase(createSellerSheetAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createSellerSheetAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editSellerSheetAction.fulfilled, (state, action) => ({
    ...state,
    ...initialErrorsState,
    sellerSheet: action.payload.data,
    isLoading: false,
  }));
  builder.addCase(editSellerSheetAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editSellerSheetAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteSellerSheetAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteSellerSheetAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteSellerSheetAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(bulkDeleteSellerSheetAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteSellerSheetAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(bulkDeleteSellerSheetAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetSellerSheetForm.type, (state) => ({
    ...state,
    ...initialErrorsState,
    sellerSheet: initialState.sellerSheet,
    summary: initialState.summary,
  }));
  builder.addCase(
    getSellerSheetPaymentMethods.fulfilled,
    (state, { payload: paymentMethodsOptions }) => ({
      ...state,
      paymentMethodsOptions,
    }),
  );
  builder.addCase(
    getSellerSheetPaymentStatuses.fulfilled,
    (state, { payload: paymentStatusesOptions }) => ({
      ...state,
      paymentStatusesOptions,
    }),
  );
  builder.addCase(
    getSellerSheetBanxExportStatuses.fulfilled,
    (state, { payload: bankExportStatusesOptions }) => ({
      ...state,
      bankExportStatusesOptions,
    }),
  );

  builder.addCase(submitSellerSheetDraft.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    sellerSheet: payload.data,
    isLoading: false,
  }));
  builder.addCase(submitSellerSheetDraft.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(submitSellerSheetDraft.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getSellerSheetBankExportPreview.pending, (state) => ({
    ...state,
    bankExport: {
      ...state.bankExport,
      isLoading: true,
    },
  }));
  builder.addCase(
    getSellerSheetBankExportPreview.fulfilled,
    (state, { payload }) => ({
      ...state,
      bankExport: {
        ...state.bankExport,
        isLoading: false,
        message: payload.message || null,
        bank_export_data: payload.bank_export_data,
      },
    }),
  );
  builder.addCase(getSellerSheetBankExportPreview.rejected, (state) => ({
    ...state,
    bankExport: {
      ...initialState.bankExport,
    },
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.sellerSheet.isLoading,
  (id) => id,
);

export const selectSellerSheets = createSelector(
  (state: RootState) => state.sellerSheet.result,
  (result) => result,
);

export const selectSellerSheetsCount = createSelector(
  (state: RootState) => state.sellerSheet.count,
  (count) => count,
);

export const selectSellerSheetsPage = createSelector(
  (state: RootState) => state.sellerSheet.page,
  (count) => count,
);

export const selectSellerSheetsPerPage = createSelector(
  (state: RootState) => state.sellerSheet.perPage,
  (count) => count,
);

export const selectSellerSheet = createSelector(
  (state: RootState) => state.sellerSheet.sellerSheet,
  (user) => user,
);

export const selectSellerSheetSummary = createSelector(
  (state: RootState) => state.sellerSheet.summary,
  (user) => user,
);
export const selectIsSummaryLoading = createSelector(
  (state: RootState) => state.sellerSheet.isSummaryLoading,
  (user) => user,
);

export const selectIsSummarySummaryErrors = createSelector(
  (state: RootState) => state.sellerSheet.summaryErrors,
  (user) => user,
);

export const selectErrors = createSelector(
  (state: RootState) => state.sellerSheet.errors,
  (errors) => errors,
);

export const selectSellerSheetPaymentMethods = createSelector(
  (state: RootState) => state.sellerSheet,
  (sellerSheet) => sellerSheet.paymentMethodsOptions.map((type) => ({
    text: type.value,
    value: type.key,
  })) as OptionsType[],
);

export const selectSellerSheetPaymentStatuses = createSelector(
  (state: RootState) => state.sellerSheet,
  (sellerSheet) => sellerSheet.paymentStatusesOptions.map((type) => ({
    text: type.value,
    value: type.key,
  })) as OptionsType[],
);

export const selectSellerSheetBankExportStatuses = createSelector(
  (state: RootState) => state.sellerSheet,
  (sellerSheet) => sellerSheet.bankExportStatusesOptions.map((type) => ({
    text: type.value,
    value: type.key,
  })) as OptionsType[],
);

export const selectSellerSheetBankExport = createSelector(
  (state: RootState) => state.sellerSheet,
  (sellerSheet) => sellerSheet.bankExport,
);

export const selectSellerSheetPaymentDetails = createSelector(
  (state: RootState) => state.sellerSheet.sellerSheet.payment_method_details,
  (payment) => payment && [
    {
      title: 'Payment Method',
      name: readablePaymentMethod[payment.method],
    },
    ...Object.entries({
      ...paymentContent?.[payment.method],
    }).map((item) => ({
      title: item[1],
      name: payment?.details?.[item[0]],
    })),
  ],
);
