import { AxiosError } from "axios";
import { isObject } from "../../../utilities";

export type Error = {
  statusCode?: number;
  error?: string;
  messages?: Record<string, string[]>;
}

export interface IStateErrors {
  errors: any,
  invoiceErrors?: any,
  errorsPayments?: any,
}

export interface IErrorData {
  data:
  | Array<{
    item: string[];
  }>
  | { detail: string }
  | { message: string }
  | {
    errors: [
      {
        clientMessage: string;
        code: number;
        description: string;
        property: string;
      }
    ];
  }
  | {
    [key: string]: {
      [key: string]: string[];
    };
  }
  | { statusDetail: string }
  | { description: string };
}

export const getErrorText = (err: AxiosError<IErrorData | string>): string => {
  const errorData = err.response?.data;
  if (!errorData) return err.message || "Server Error";
  if (typeof errorData === "string") return `Server Error With Status ${err.response?.status}`;
  if (typeof errorData.data === "string") return errorData.data;
  if (errorData?.data instanceof Array) return errorData.data[0].item[0];
  if ("errors" in errorData.data) {
    if ("description" in errorData.data.errors[0]) return errorData.data.errors[0].description;
  }
  const firstErrorValue = Object.values(errorData.data)[0];

  if (isObject(firstErrorValue)) {
    const secondLevelValue = Object.values(firstErrorValue)[0];
    // @ts-ignore
    const message = secondLevelValue[0];
    return message;
  }
  if (firstErrorValue instanceof Array) {
    return firstErrorValue[0];
  }

  if (typeof firstErrorValue === "string") return firstErrorValue;
  return "Server Error";
};
