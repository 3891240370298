import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  AuctionPatchType,
  AuctionState, AuctionType,
  EditAuctionResponse,
  EditAuctionType,
  GetAuctionResponse, GetAuctionSeqNoResponse, GetAuctionSeqNoType,
  GetAuctionsResponse,
  GetAuctionsType,
  GetAuctionType,
  PostAuctionResponse,
  PostAuctionType,
} from './auctions.types';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: AuctionState = {
  result: [],
  auction: {
    id: null,
    name: null,
    description: '',
    sequence_no: null,
    start_date: '',
    end_date: '',
    is_published: false,
    dates: [null, null],
    picture: null,
  },
  isLoading: false,
  isAuctionLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('auctions');

export const getAuctionsAction = createAsyncThunk<GetAuctionsType, PaginationType & any>('getAuctions',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetAuctionsResponse>('/api/auctions/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getAuctionAction = createAsyncThunk<GetAuctionType, { id?: number }>('getAuction',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, GetAuctionResponse>(`/api/auctions/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getSeqNoAuctionAction = createAsyncThunk<GetAuctionSeqNoType, void>('getSeqNoAuction',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<void, GetAuctionSeqNoResponse>('/api/auctions/next_seq_no/');
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createAuctionAction = createAsyncThunk<PostAuctionType, AuctionType>('createAuction',
  async ({
    name,
    description,
    sequence_no,
    start_date,
    end_date,
    is_published,
    picture,
    group_lot_entry_fee,
    entry_fee,
    reserve_fee,
    sellers_commission_limit,
    sellers_commission,
  }, thunkAPI) => {
    try {
      const formData = serialize({
        name,
        description,
        sequence_no,
        start_date,
        end_date,
        is_published,
        picture: picture || undefined,
        entry_fee,
        reserve_fee,
        group_lot_entry_fee,
        sellers_commission,
        sellers_commission_limit,
      }, { indices: true }, undefined, 'object');

      const response = await thunkAPI.extra.post<AuctionType, PostAuctionResponse>('/api/auctions/', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editAuctionAction = createAsyncThunk<EditAuctionType, AuctionPatchType>('editAuctions',
  async ({
    id,
    name,
    description,
    sequence_no,
    start_date,
    end_date,
    is_published,
    picture,
    entry_fee,
    reserve_fee,
    group_lot_entry_fee,
    sellers_commission,
    sellers_commission_limit,
  }, thunkAPI) => {
    try {
      const formData = serialize({
        name,
        description,
        sequence_no,
        start_date,
        end_date,
        is_published,
        picture,
        entry_fee,
        reserve_fee,
        sellers_commission,
        group_lot_entry_fee,
        sellers_commission_limit,
      }, { indices: true }, undefined, 'object');
      const response = await thunkAPI.extra.patch<AuctionType, EditAuctionResponse>(`/api/auctions/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteAuctionAction = createAsyncThunk<null, { id?: number }>('deleteAuctions',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/auctions/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetAuctionForm = createAction('resetAuctionForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(getAuctionsAction.fulfilled, (state, {
    payload,
    meta,
  }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    count: payload.data.count,
    isLoading: false,
  }));
  builder.addCase(getAuctionsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getAuctionsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getAuctionAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    auction: payload.data,
    isAuctionLoading: false,
  }));
  builder.addCase(getAuctionAction.pending, (state) => ({
    ...state,
    isAuctionLoading: true,
  }));
  builder.addCase(getAuctionAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isAuctionLoading: false,
  }));
  builder.addCase(createAuctionAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    auction: payload.data,
    result: [...state.result, payload.data],
    isLoading: false,
  }));
  builder.addCase(createAuctionAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createAuctionAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editAuctionAction.fulfilled, (state, action) => {
    const auctions = state.result.map((auction) => {
      if (auction.id === action.payload.data.id) {
        return action.payload.data;
      }
      return auction;
    });

    return ({
      ...state,
      ...initialErrorsState,
      auction: action.payload.data,
      result: auctions,
      isLoading: false,
    });
  });
  builder.addCase(editAuctionAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editAuctionAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteAuctionAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteAuctionAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteAuctionAction.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(getSeqNoAuctionAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    auction: {
      ...state.auction,
      sequence_no: payload.data.available_sequence_no,
    },
    isLoading: false,
  }));
  builder.addCase(getSeqNoAuctionAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getSeqNoAuctionAction.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(resetAuctionForm.type, (state) => ({
    ...state,
    ...initialErrorsState,
    auction: initialState.auction,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.auctions.isLoading,
  (id) => id,
);

export const selectAuctionLoadingStatus = createSelector(
  (state: RootState) => state.auctions.isAuctionLoading,
  (id) => id,
);

export const selectAuctions = createSelector(
  (state: RootState) => state.auctions.result,
  (id) => id,
);

export const selectAuctionsCount = createSelector(
  (state: RootState) => state.auctions.count,
  (id) => id,
);

export const selectAuctionsPage = createSelector(
  (state: RootState) => state.auctions.page,
  (id) => id,
);

export const selectAuctionsPerPage = createSelector(
  (state: RootState) => state.auctions.perPage,
  (id) => id,
);

export const selectAuction = createSelector(
  (state: RootState) => state.auctions.auction,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.auctions.errors,
  (id) => id,
);
