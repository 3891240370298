import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { dispatchErrorMessage, successMessageAction } from '../ui/ui';
import { ISellerSheetPaymentRecordsState, TSellerSheetPaymentRecordBody, TSellerSheetPaymentRecordsResponse } from './sellerSheetPaymentRecords.types';

const initialState: ISellerSheetPaymentRecordsState = {
  result: [],
  error: null,
  isLoading: false,
};
const createAsyncThunk = createEnhancedThunk('sellerSheetPaymentRecords');

export const getSellerSheetPaymentRecords = createAsyncThunk<TSellerSheetPaymentRecordsResponse['data'], number>('getSellerSheetPaymentRecords',
  async (sellerSheetId, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TSellerSheetPaymentRecordsResponse>(`/api/seller_sheets/${sellerSheetId}/payments/`);
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const updateSellerSheetPaymentRecords = createAsyncThunk<TSellerSheetPaymentRecordsResponse['data'], TSellerSheetPaymentRecordBody>('postSellerSheetPaymentRecord',
  async ({ sellerSheetId, body }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<TSellerSheetPaymentRecordsResponse>(`/api/seller_sheets/${sellerSheetId}/payments/list_update_completely/`, body);
      thunkAPI.dispatch(successMessageAction("Payment Records have been updated successfully"));
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getSellerSheetPaymentRecords.pending, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }));
  builder.addCase(getSellerSheetPaymentRecords.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: null,
    result: payload,
  }));
  builder.addCase(getSellerSheetPaymentRecords.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(updateSellerSheetPaymentRecords.pending, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  }));
  builder.addCase(updateSellerSheetPaymentRecords.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    error: null,
    result: payload,
  }));
  builder.addCase(updateSellerSheetPaymentRecords.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));
});

export const selectSellerSheetPaymentRecordsData = createSelector(
  (state: RootState) => state.sellerSheetPaymentRecords,
  (sellerSheetPaymentRecords) => sellerSheetPaymentRecords,
);
