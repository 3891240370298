import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  EditMiscellaneousChargesResponse,
  EditMiscellaneousChargesType,
  GetMiscellaneousChargesResponse,
  GetMiscellaneousChargesType,
  MiscellaneousChargesStateType,
  MiscellaneousChargesType,
  PostMiscellaneousChargesResponse,
  PostMiscellaneousChargesType,
} from './sellerSheetMiscellaneousCharges.types';

const initialErrorsState = {
  errors: null,
};

const initialState: MiscellaneousChargesStateType = {
  result: [],
  count: 0,
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('sellerSheetMiscellaneousCharges');

export const getMiscellaneousChanges = createAsyncThunk<GetMiscellaneousChargesType, { id?: number }>('getMiscellaneousChanges',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, GetMiscellaneousChargesResponse>(`/api/seller_sheets/${id}/miscellaneous_charges/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteMiscellaneousAction = createAsyncThunk<null, { id?: number, itemId?: number }>('deleteMiscellaneousAction',
  async ({ id, itemId }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/seller_sheets/${id}/miscellaneous_charges/${itemId}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editMiscellaneousAction = createAsyncThunk<EditMiscellaneousChargesType, { id?: number, itemId?: number, data:MiscellaneousChargesType }>('editMiscellaneousAction',
  async ({ id, itemId, data }, thunkAPI) => {
    try {
      const result = await thunkAPI.extra.patch<{ id?: number, itemId?: number}, EditMiscellaneousChargesResponse>(`/api/seller_sheets/${id}/miscellaneous_charges/${itemId}/`, data);
      return result.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createMiscellaneousAction = createAsyncThunk<PostMiscellaneousChargesType, { id?: number, data:any }>('createMiscellaneousAction',
  async ({ id, data }, thunkAPI) => {
    try {
      const result = await thunkAPI.extra.post<{ id?: number }, PostMiscellaneousChargesResponse>(`/api/seller_sheets/${id}/miscellaneous_charges/`, data);
      return result.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const resetMiscellaneousForm = createAction('resetMiscellaneousForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(getMiscellaneousChanges.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    isLoading: false,
  }));
  builder.addCase(getMiscellaneousChanges.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getMiscellaneousChanges.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(createMiscellaneousAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    result: [...state.result, payload.data],
    count: state.count + 1,
    isLoading: false,
  }));
  builder.addCase(createMiscellaneousAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createMiscellaneousAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editMiscellaneousAction.fulfilled, (state, { payload }) => {
    const miscellaneouses = state.result.map((miscellaneous:any) => {
      if (miscellaneous.id === payload?.data?.id) {
        return payload.data;
      }
      return miscellaneous;
    });
    return ({
      ...state,
      ...initialErrorsState,
      result: miscellaneouses,
      isLoading: false,
    });
  });
  builder.addCase(editMiscellaneousAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editMiscellaneousAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(deleteMiscellaneousAction.fulfilled, (state, action) => {
    const filteredMiscellaneous = state
      .result
      .filter((item:any) => item.id !== action.meta.arg.itemId);
    return ({
      ...state,
      ...initialErrorsState,
      result: filteredMiscellaneous,
      count: state.count - 1,
      isLoading: false,
    });
  });
  builder.addCase(deleteMiscellaneousAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteMiscellaneousAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetMiscellaneousForm.type, () => ({
    ...initialState,
    ...initialErrorsState,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.sellerSheetMiscellaneousCharges.isLoading,
  (id) => id,
);

export const selectMiscellaneousChanges = createSelector(
  (state: RootState) => state.sellerSheetMiscellaneousCharges.result,
  (result) => result,
);

export const selectMiscellaneousChangesCount = createSelector(
  (state: RootState) => state.sellerSheetMiscellaneousCharges.count,
  (count) => count,
);
