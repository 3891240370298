import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Props } from './Page.types';

const Page: FC<PropsWithChildren<Props>> = ({ children, title }) => {
  useEffect(() => {
    document.title = `JW | ${title}` || '';
  }, [title]);
  return <>{children}</>;
};
export default Page;
