import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

const initialErrorsState = {
  errors: null,
};

const initialState: any = {
  result: [],
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('images');

export const getImages = createAsyncThunk<any, any>('getImages',
  async ({ imageIds }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>('/api/images/', {
        params: {
          offset: 0,
          limit: 50,
          id: imageIds,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const reset = createAction('reset');

export default createReducer(initialState, (builder) => {
  builder.addCase(getImages.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    isLoading: false,
  }));
  builder.addCase(getImages.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getImages.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(reset.type, (state) => ({
    ...state,
    ...initialErrorsState,
    result: initialState.result,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.images.isLoading,
  (id) => id,
);

export const selectImages = createSelector(
  (state: RootState) => state.images.result,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.images.errors,
  (id) => id,
);
