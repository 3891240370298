import React, { FC, PropsWithChildren } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-gb';

const LocalizationProviderWrapper:FC<PropsWithChildren<{}>> = ({ children }) => (
  <LocalizationProvider
    dateAdapter={MomentUtils}
  >
    {children}
  </LocalizationProvider>
);

export default LocalizationProviderWrapper;
