import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Card, CircularProgress, Grid, IconButton, InputAdornment, TextField, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { cyan } from '@mui/material/colors';
import Cookies from 'universal-cookie';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Inputs, InputsShowPassword, Props } from './ResetPasswordStep3.types';
import { addServerErrors } from '../../../utilities';
import { useAppDispatch } from '../../../redux/store';
import {
  forgetPasswordConfirm,
  selectForgetConfirmErrors,
  selectLoadingStatus,
} from '../../../redux/ducks/forgetPassword/forgetPassword';
import { ErrorsList } from '../../atoms/ErrorsList';
import routes from '../../../routes';
import { passwordRules } from '../../../validationRules';

const useStyles = makeStyles((theme:Theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  button: {
    position: 'relative',
    margin: theme.spacing(0, 0, 0),
  },
  buttonProgress: {
    color: cyan[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  [theme.breakpoints.down('sm')]: {
    buttons: {
      marginTop: theme.spacing(0),
    },
    button: {
      margin: theme.spacing(0),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const ResetPasswordStep3: FC<Props> = ({
  onSubmitStep,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const forgetError = useSelector(selectForgetConfirmErrors);
  const isLoading = useSelector(selectLoadingStatus);
  const [passwordsShow, setPasswordsShow] = useState<InputsShowPassword>({
    showPassword: false,
    showRepeatPassword: false,
  });
  const [serverErrors, setErrors] = useState({});
  const {
    control, watch, handleSubmit, setError,
  } = useForm<Inputs>({
    defaultValues: {
      password: '',
      repeat_password: '',
    },
  });

  const handleClickShowPassword = (type: 'showPassword' | 'showRepeatPassword') => () => {
    setPasswordsShow({
      ...passwordsShow,
      [type]: !passwordsShow[type],
    });
  };

  const submitReset = async (data: Inputs) => {
    const cookies = new Cookies();
    const token = cookies.get('emailToken');
    const result = await dispatch(forgetPasswordConfirm(
      {
        password: data.password,
        token,
      },
    ));
    if (forgetPasswordConfirm.fulfilled.match(result)) {
      setErrors({});
      cookies.remove('emailToken', { path: '/' });
      history.push(routes.login);
    }
  };

  const goBack = () => {
    onSubmitStep(2);
  };

  useEffect(() => {
    setErrors({});
    if (forgetError) {
      setErrors(addServerErrors(forgetError, setError, {
        password: '',
        repeat_password: '',
      }));
    }
  }, [forgetError]);

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component="h1" variant="h5">
        Enter new password
      </Typography>
      <form onSubmit={handleSubmit(submitReset)} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={passwordRules}
              name="password"
              render={({
                field: { onChange, value, ref },
                fieldState,
              }) => (
                <TextField
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  id="password"
                  label="Password"
                  autoComplete="password"
                  autoFocus
                  type={passwordsShow.showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('showPassword')}
                          edge="end"
                        >
                          {passwordsShow.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="repeat_password"
              rules={{
                ...passwordRules,
                validate: (value) => value === watch('password') || 'The passwords do not match',
              }}
              render={({
                field: { onChange, value, ref },
                fieldState,
              }) => (
                <TextField
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  error={!!fieldState.error?.message}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  id="repeat_password"
                  label="Repeat password"
                  autoComplete="repeat_password"
                  helperText={fieldState?.error?.message}
                  type={passwordsShow.showRepeatPassword ? 'text' : 'password'}
                  placeholder="Enter your repeat password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword('showRepeatPassword')}
                          edge="end"
                        >
                          {passwordsShow.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.buttons}>
          <Grid item xs={12}>
            {serverErrors && (<ErrorsList errors={serverErrors} />)}
          </Grid>
          <Grid container item xs={12} md={6}>
            <Button
              type="button"
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={goBack}
              className={classes.button}
              size="large"
            >
              Go back
            </Button>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              className={classes.button}
              size="large"
            >
              Submit
              {isLoading && (<CircularProgress size={24} color="secondary" className={classes.buttonProgress} />)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default ResetPasswordStep3;
