import React, { FC } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { selectCurrentModal } from '../../../redux/ducks/ui/ui';
import { Props } from './SuccessfulModal.types';
import { Transition } from '../../atoms/Transition';

const useStyles = makeStyles(() => ({
  root: {
    'z-index': '2003 !important',
  },
  paper: {
    width: '400px',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
  },
  text: {
    textAlign: 'center',
  },
}));

const SuccessfulModal: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const currentModal = useSelector(selectCurrentModal);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="alert-dialog-title">
        <AssignmentIcon color="primary" className={classes.icon} fontSize="large" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.text}>
          {currentModal?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessfulModal;
