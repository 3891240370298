import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';
import {
  GetOrdersResponse,
  TOrderPayment,
  IOrdersState,
  PaymentMethodsOrderResponse,
  PaymentStatusesOrderResponse,
  ServiceMethodsOrderResponse,
  TGetOrderPaymentsResponse,
  TMutationOrderPaymentsResponse,
  TOrderPaymentBody,
} from './orders.types';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { handleDownloadFile, toCurrency, withoutKey } from '../../../utilities';
import { dispatchErrorMessage } from '../ui/ui';
import { OptionsType } from '../../../components/molecules/Filters/Filters';
import { ResponseFile } from '../../apiTypes/shared/response';

const initialErrorsState = {
  errors: null,
  invoiceErrors: null,
  errorsPayments: null,
};

const initialState: IOrdersState = {
  result: [],
  order: {},
  total: '0',
  isLoading: false,
  payments: {
    isLoading: false,
    result: [],
  },
  paymentMethodsOptions: [],
  paymentStatusesOptions: [],
  serviceMethodsOptions: [],
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('orders');

export const getOrders = createAsyncThunk<GetOrdersResponse['data'], PaginationType & any>('getOrders',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<GetOrdersResponse>('/api/orders/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const getUserOrders = createAsyncThunk<GetOrdersResponse['data'], PaginationType & any>('getUserOrders',
  async ({
    userId, page, perPage, params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<GetOrdersResponse>(`/api/users/${userId}/orders/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getOrder = createAsyncThunk<any, { id?: number }>('getOrder',
  async ({
    id,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, any>(`/api/orders/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const getInvoiceOrder = createAsyncThunk<void, { id?: number }>('getInvoiceOrder',
  async ({
    id,
  }, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<ResponseFile>(`/api/orders/${id}/get_pdf/`);
      handleDownloadFile({ b64: res.data.data.file, filename: res.data.data.filename, mime_type: res.data.data.mime_type });
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getInvoiceOrderBulk = createAsyncThunk<
  void,
  { objects?: number[] }
>('getInvoiceOrderBulk', async ({ objects }, thunkAPI) => {
  try {
    const res = await thunkAPI.extra.post<ResponseFile>(
      `/api/orders/list_get_pdf/`,
      { objects },
    );
    handleDownloadFile({
      action: 'print',
      b64: res.data.data.file,
      filename: res.data.data.filename,
      mime_type: res.data.data.mime_type,
    });
    return undefined;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const setOrderStatusBulk = createAsyncThunk<void, { objects?: number[], status: number }>('setOrderStatusBulk',
  async ({
    objects,
    status,
  }, thunkAPI) => {
    try {
      await thunkAPI.extra.post<ResponseFile>(`/api/orders/list-set-order-status/`, { objects, status });
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const setOrderStatus = createAsyncThunk<any, { id?: number, statusId?: number, }>('setOrderStatus',
  async ({
    id, statusId,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<{ id?: number, statusId?: number, }, any>(`/api/orders/${id}/set-order-status/`, {
        status: statusId,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const patchOrder = createAsyncThunk<any, { id?: number, data?: any, }>('patchOrder',
  async ({
    id, data,
  }, thunkAPI) => {
    try {
      const response = Object.keys(withoutKey(data, ['text'])).length !== 0 && await thunkAPI.extra.patch<{ id?: number, statusId?: number, }, any>(`/api/orders/${id}/`,
        withoutKey(data, ['text']));
      if (data.text) {
        const responseInvoice = await thunkAPI.extra.patch<{ id?: number, statusId?: number, }, any>(`/api/orders/${id}/set-invoice-note/`, {
          text: data.text,
        });
        return responseInvoice.data;
      }
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getPaymentRecordOrder = createAsyncThunk<TOrderPayment[], { id: number }>('getPaymentRecordOrder',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TGetOrderPaymentsResponse>(`/api/orders/${id}/payments/`);
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const addPaymentRecordOrder = createAsyncThunk<TOrderPayment, { id?: number, body?: TOrderPaymentBody, }>('addPaymentRecordOrder',
  async ({
    id, body,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<TMutationOrderPaymentsResponse>(`/api/orders/${id}/payments/`, body);
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deletePaymentRecordOrder = createAsyncThunk<void, { paymentId: number, id?: number }>('deletePaymentRecordOrder',
  async ({
    id, paymentId,
  }, thunkAPI) => {
    try {
      await thunkAPI.extra.delete<void>(`/api/orders/${id}/payments/${paymentId}/`);
      return undefined;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const patchPaymentRecordOrder = createAsyncThunk<TOrderPayment[] | null, { paymentId: number, id?: number, body?: Partial<TOrderPaymentBody>, }>('patchPaymentRecordOrder',
  async ({
    id, body, paymentId,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<TMutationOrderPaymentsResponse>(`/api/orders/${id}/payments/${paymentId}/`, body);
      const { data } = response.data;
      const state = thunkAPI.getState();
      const newPaymentsList = state.orders.payments?.result?.map((payment) => (payment.id === paymentId ? data : payment)) || null;
      return newPaymentsList;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getOrdersPaymentMethods = createAsyncThunk<PaymentMethodsOrderResponse['data'], void>('getOrdersPaymentMethods',
  async (_, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<PaymentMethodsOrderResponse>(`/api/orders/payment_method_list/`);
      return res.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getOrdersPaymentStatuses = createAsyncThunk<PaymentStatusesOrderResponse['data'], void>('getOrdersPaymentStatuses',
  async (_, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<PaymentStatusesOrderResponse>(`/api/orders/payment_status_list/`);
      return res.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getOrdersServiceMethods = createAsyncThunk<ServiceMethodsOrderResponse['data'], void>('getOrdersServiceMethods',
  async (_, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<ServiceMethodsOrderResponse>(`/api/orders/service_method_list/`);
      return res.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const resetOrderForm = createAction('resetOrderForm');
export const resetOrders = createAction('resetOrders');

export default createReducer(initialState, (builder) => {
  builder.addCase(getOrders.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.results,
    count: payload.count,
    total: payload.total_order_costs,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getOrders.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOrders.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getUserOrders.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.results,
    count: payload.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    total: payload.total_order_costs,
    isLoading: false,
  }));
  builder.addCase(getUserOrders.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getUserOrders.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getOrder.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    order: payload.data,
    isLoading: false,
  }));
  builder.addCase(getOrder.pending, (state) => ({
    ...state,
    order: {
      ...initialState.order,
    },
    isLoading: true,
  }));
  builder.addCase(getOrder.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(setOrderStatus.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    order: payload.data,
    isLoading: false,
  }));
  builder.addCase(setOrderStatus.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setOrderStatus.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(patchOrder.fulfilled, (state, action) => {
    const orders = state.result.map((order: any) => {
      if (order.id === action.payload.data.id) {
        return action.payload.data;
      }
      return order;
    });
    return ({
      ...state,
      ...initialErrorsState,
      order: {
        ...state.order,
        ...action.payload.data,
      },
      result: orders,
      isLoading: false,
    });
  });
  builder.addCase(patchOrder.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(patchOrder.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getPaymentRecordOrder.pending, (state) => ({
    ...state,
    payments: {
      ...state.payments,
      isLoading: true,
    },
  }));

  builder.addCase(getPaymentRecordOrder.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    payments: {
      ...state.payments,
      isLoading: false,
      result: payload,
    },
  }));

  builder.addCase(getPaymentRecordOrder.rejected, (state) => ({
    ...state,
    payments: {
      ...state.payments,
      isLoading: false,
      result: null,
    },
  }));

  builder.addCase(deletePaymentRecordOrder.fulfilled, (state) => ({
    ...state,
    payments: {
      ...state.payments,
      isLoading: false,
    },
  }));

  builder.addCase(deletePaymentRecordOrder.pending, (state) => ({
    ...state,
    payments: {
      ...state.payments,
      isLoading: true,
    },
  }));

  builder.addCase(deletePaymentRecordOrder.rejected, (state) => ({
    ...state,
    payments: {
      ...state.payments,
      isLoading: false,
    },
  }));

  builder.addCase(addPaymentRecordOrder.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    payments: {
      ...state.payments,
      isLoading: false,
      result: [...(state.payments.result || []), payload],
    },
  }));
  builder.addCase(addPaymentRecordOrder.pending, (state) => ({
    ...state,
    ...initialErrorsState,
    payments: {
      ...state.payments,
      isLoading: true,
    },
  }));
  builder.addCase(addPaymentRecordOrder.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errorsPayments: action?.payload?.messages,
    payments: {
      ...state.payments,
      isLoading: false,
    },
  }));

  builder.addCase(patchPaymentRecordOrder.pending, (state) => ({
    ...state,
    ...initialErrorsState,
    payments: {
      ...state.payments,
      isLoading: true,
    },
  }));

  builder.addCase(patchPaymentRecordOrder.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    payments: {
      ...state.payments,
      isLoading: false,
      result: payload,
    },
  }));

  builder.addCase(patchPaymentRecordOrder.rejected, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errorsPayments: payload?.messages,
    payments: {
      ...state.payments,
      isLoading: false,
    },
  }));

  builder.addCase(resetOrderForm, (state) => ({
    ...state,
    ...initialErrorsState,
  }));
  builder.addCase(resetOrders, () => ({
    ...initialState,
    ...initialErrorsState,
  }));
  builder.addCase(getOrdersPaymentMethods.fulfilled, (state, { payload: paymentMethodsOptions }) => ({
    ...state,
    paymentMethodsOptions,
  }));
  builder.addCase(getOrdersPaymentStatuses.fulfilled, (state, { payload: paymentStatusesOptions }) => ({
    ...state,
    paymentStatusesOptions,
  }));
  builder.addCase(getOrdersServiceMethods.fulfilled, (state, { payload: serviceMethodsOptions }) => ({
    ...state,
    serviceMethodsOptions,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.orders.isLoading,
  (id) => id,
);

export const selectOrders = createSelector(
  (state: RootState) => state.orders.result,
  (result) => result,
);

export const selectOrder = createSelector(
  (state: RootState) => state.orders.order,
  (result) => result,
);

export const selectOrdersCount = createSelector(
  (state: RootState) => state.orders.count,
  (count) => count,
);

export const selectOrdersTotal = createSelector(
  (state: RootState) => state.orders.total,
  (total) => toCurrency(total ? +total : 0),
);

export const selectOrdersPage = createSelector(
  (state: RootState) => state.orders.page,
  (page) => page,
);

export const selectOrdersPerPage = createSelector(
  (state: RootState) => state.orders.perPage,
  (page) => page,
);

export const selectOrdersErrors = createSelector(
  (state: RootState) => state.orders.errors,
  (page) => page,
);

export const selectPaymentsErrors = createSelector(
  (state: RootState) => state.orders.errorsPayments,
  (page) => page,
);

export const selectOrderPayments = createSelector(
  (state: RootState) => state.orders.payments,
  (payments) => payments,
);

export const selectOrdersPaymentMethods = createSelector(
  (state: RootState) => state.orders,
  (orders) => orders.paymentMethodsOptions.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);

export const selectOrdersPaymentStatuses = createSelector(
  (state: RootState) => state.orders,
  (orders) => orders.paymentStatusesOptions.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);

export const selectOrdersServiceMethods = createSelector(
  (state: RootState) => state.orders,
  (orders) => orders.serviceMethodsOptions.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);
