import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import { stringify } from 'qs';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  TemplateState,
  TemplateType,
  EditTemplateResponse,
  EditTemplateType,
  GetTemplateResponse,
  GetTemplatesResponse,
  GetTemplatesType,
  GetTemplateType,
  PostTemplateResponse,
  PostTemplateType, TemplatePatchType, GetTemplatePriceHistoryResponse, TemplatePriceHistory, TemplatePriceHistoryParams, TemplateDependiveLotsResponse,
} from './templates.types';
import { AsyncOptionsTypes } from '../asyncSelects/asyncSelects.types';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage, errorMessageAction, setCurrentModal } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: TemplateState<string | AsyncOptionsTypes> = {
  result: [],
  priceHistory: {
    ...tablePaginationParams,
    result: [],
    isLoading: false,
  },
  template: {
    id: null,
    distillery: '',
    bottler: '',
    cask_type: '',
    cask_finish: '',
    strength: '',
    size: '',
    item_type: '',
    photo: null,
    title: '',
    barcode: '',
    subtitle: '',
    description: '',
    cask_number: null,
    approx_shipping_weight: null,
    bottles_produced: null,
  },
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('templates');

export const getTemplatesAction = createAsyncThunk<GetTemplatesType, PaginationType & any>('getTemplates',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetTemplatesResponse>('/api/items/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getTemplateAction = createAsyncThunk<GetTemplateType, { id?: number }>('getTemplate',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, GetTemplateResponse>(`/api/items/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createTemplateAction = createAsyncThunk<PostTemplateType, TemplateType<AsyncOptionsTypes>>('createTemplate',
  async ({
    distillery,
    bottler,
    cask_type,
    cask_finish,
    strength,
    size,
    item_type,
    photo,
    title,
    barcode,
    subtitle,
    description,
    cask_number,
    approx_shipping_weight,
    bottles_produced,
  }, thunkAPI) => {
    try {
      const formData = serialize({
        photo,
        title,
        barcode,
        subtitle,
        description,
        cask_number,
        approx_shipping_weight,
        bottles_produced,
        item_type: item_type?.id,
        strength: strength?.id,
        size: size?.id,
        distillery: distillery?.id,
        bottler: bottler?.id,
        cask_type: cask_type?.id,
        cask_finish: cask_finish?.id,
      }, { indices: true }, undefined, 'object');

      const response = await thunkAPI.extra.post<TemplateType<AsyncOptionsTypes>, PostTemplateResponse>('/api/items/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editTemplateAction = createAsyncThunk<EditTemplateType, TemplatePatchType<AsyncOptionsTypes>>('editTemplates',
  async ({
    id,
    distillery,
    bottler,
    cask_type,
    cask_finish,
    strength,
    size,
    item_type,
    photo,
    title,
    barcode,
    subtitle,
    description,
    cask_number,
    approx_shipping_weight,
    bottles_produced,
    is_template,
  }, thunkAPI) => {
    try {
      const formData = serialize({
        photo,
        title,
        barcode,
        subtitle,
        description,
        cask_number,
        approx_shipping_weight,
        bottles_produced,
        item_type: item_type?.id,
        strength: typeof strength === 'string' ? null : strength?.id,
        size: typeof size === 'string' ? null : size?.id,
        distillery: typeof distillery === 'string' ? null : distillery?.id,
        bottler: typeof bottler === 'string' ? null : bottler?.id,
        cask_type: cask_type?.id,
        cask_finish: cask_finish?.id,
        is_template,
      }, { indices: true }, undefined, 'object');

      const response = await thunkAPI.extra.patch<TemplateType<AsyncOptionsTypes | string>, EditTemplateResponse>(`/api/items/${id}/`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteTemplateAction = createAsyncThunk<null, { id?: number, skipDependiveCheck?: boolean, cb:() => void }>('deleteTemplates',
  async ({ id, skipDependiveCheck, cb }, thunkAPI) => {
    try {
      if (skipDependiveCheck) {
        await thunkAPI.extra.delete(`/api/items/${id}/`);
        cb();
        return null;
      }

      const { data: { data: { depending_lots_exist, message } } } = await thunkAPI.extra.post<TemplateDependiveLotsResponse>(`/api/items/depending_lots_exist/`, {
        objects: [id],
      });

      if (depending_lots_exist) {
        thunkAPI.dispatch(setCurrentModal({
          modal: "confirmModal",
          title: "Warning!",
          description: message,
          callback: async () => {
            await thunkAPI.dispatch(deleteTemplateAction({ id, skipDependiveCheck: true, cb }));
          },
        }));
      }
      if (!depending_lots_exist) {
        await thunkAPI.extra.delete(`/api/items/${id}/`);
        cb();
      }
      return null;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Template was not deleted"));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkDeleteTemplateAction = createAsyncThunk<null, {ids: number[], skipDependiveCheck?: boolean, cb:() => void }>('bulkDeleteTemplateAction',
  async ({ ids, skipDependiveCheck, cb }, thunkAPI) => {
    try {
      const deleteRequest = async () => {
        await thunkAPI.extra.delete('/api/items/', {
          headers: {
            'X-BULK-OPERATION': 'true',
          },
          params: {
            id: ids,
          },
          paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
        });
      };

      if (skipDependiveCheck) {
        await deleteRequest();
        cb();
        return null;
      }

      const { data: { data: { depending_lots_exist, message } } } = await thunkAPI.extra.post<TemplateDependiveLotsResponse>(`/api/items/depending_lots_exist/`, {
        objects: ids,
      });

      if (depending_lots_exist) {
        thunkAPI.dispatch(setCurrentModal({
          modal: "confirmModal",
          title: "Warning!",
          description: message,
          callback: async () => {
            await thunkAPI.dispatch(bulkDeleteTemplateAction({ ids, skipDependiveCheck: true, cb }));
          },
        }));
      }
      if (!depending_lots_exist) {
        await deleteRequest();
        cb();
      }
      return null;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Template was not deleted"));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getTemplatePriceHistory = createAsyncThunk<{
  result: TemplatePriceHistory[],
  count: number,
}, PaginationType & TemplatePriceHistoryParams & { id?: number }>('getTemplatePriceHistory',
  async ({
    id,
    page,
    perPage,
    params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<GetTemplatePriceHistoryResponse>(`/api/items/${id}/price_history/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return {
        count: response.data.data.count,
        result: response.data.data.results,
      };
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetTemplateForm = createAction('resetTemplateForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(getTemplatesAction.fulfilled, (state, {
    payload,
    meta,
  }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getTemplatesAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getTemplatesAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getTemplateAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    template: payload.data,
    isLoading: false,
  }));
  builder.addCase(getTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(createTemplateAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    template: payload.data,
    isLoading: false,
  }));
  builder.addCase(createTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(editTemplateAction.fulfilled, (state, action) => {
    const templates = state.result.map((template) => {
      if (template.id === action.payload.data.id) {
        return action.payload.data;
      }
      return template;
    });

    return ({
      ...state,
      ...initialErrorsState,
      template: action.payload.data,
      result: templates,
      isLoading: false,
    });
  });
  builder.addCase(editTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteTemplateAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(bulkDeleteTemplateAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteTemplateAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(bulkDeleteTemplateAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetTemplateForm.type, (state) => ({
    ...state,
    ...initialErrorsState,
    template: initialState.template,
  }));

  builder.addCase(getTemplatePriceHistory.fulfilled, (state, {
    payload,
    meta,
  }) => ({
    ...state,
    ...initialErrorsState,
    priceHistory: {
      ...state.priceHistory,
      result: payload.result,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getTemplatePriceHistory.pending, (state) => ({
    ...state,
    priceHistory: {
      ...state.priceHistory,
      isLoading: true,
    },
  }));
  builder.addCase(getTemplatePriceHistory.rejected, (state) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    priceHistory: {
      ...state.priceHistory,
      isLoading: false,
    },
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.templates.isLoading,
  (id) => id,
);

export const selectTemplates = createSelector(
  (state: RootState) => state.templates.result,
  (id) => id,
);

export const selectTemplatesCount = createSelector(
  (state: RootState) => state.templates.count,
  (id) => id,
);

export const selectTemplate = createSelector(
  (state: RootState) => state.templates.template,
  (id) => id,
);

export const selectTemplatePage = createSelector(
  (state: RootState) => state.templates.page,
  (id) => id,
);

export const selectTemplatePerPage = createSelector(
  (state: RootState) => state.templates.perPage,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.templates.errors,
  (id) => id,
);

export const selectTemplatePriceHistory = createSelector(
  (state: RootState) => state.templates,
  (templates) => templates.priceHistory,
);
