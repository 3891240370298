import { TPaymentMethod } from './paymentMethods.types';

const readablePaymentMethod: Record<TPaymentMethod['method'], string> = {
  INT_BANK: 'International Bank',
  UK_BANK: 'UK Bank',
  PAYPAL: 'Paypal',
  WALLET: 'Wallet',
  CARD: 'Credit Card',
};

export default readablePaymentMethod;
