import React, { ComponentType, FC } from 'react';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { MemoryRouterProps } from 'react-router';
import { BrowserRouter, BrowserRouterProps, MemoryRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import store from './redux/store';
import AppThemeProvider from './AppThemeProvider';

interface Props {
  // eslint-disable-next-line react/require-default-props
  initialEntries?: MemoryRouterProps['initialEntries'];
}

let Router: ComponentType<BrowserRouterProps | MemoryRouterProps> = BrowserRouter;
if (process.env.JEST_WORKER_ID !== undefined) {
  Router = MemoryRouter;
}

const AppProvider: FC<Props> = ({ initialEntries }) => {
  moment.tz.setDefault('GMT');

  return (
    <Provider store={store}>
      <Router initialEntries={initialEntries}>
        <AppThemeProvider />
      </Router>
    </Provider>
  );
};

export default AppProvider;
