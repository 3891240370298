import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  IUserStorageLotsResponse,
  IStorageState,
  IUserStoragesResponse,
  TUserStorageParams,
  IStorageWarehouseResponse,
  TWarehouseParams,
  TWarehouseLotsParams,
  IStorageWarehouseLotsResponse,
  IStorageWarehouseDetailsResponse,
  IPostWarehouseResponse,
  IWarehouse,
} from './orderStorage.types';
import { dispatchErrorMessage, errorMessageAction, successMessageAction } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: IStorageState = {
  userStorageLots: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  userStorages: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  warehouses: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  warehouseLots: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  warehouseDetails: {
    id: 0,
    name: '',
    lots_in_storage_num: 0,
  },
};
const createAsyncThunk = createEnhancedThunk('orderStorage');

export const getUserStorageLots = createAsyncThunk<IUserStorageLotsResponse['data'], PaginationType & { userId: number }>('getUserStorageLots',
  async ({ page, perPage, userId }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<IUserStorageLotsResponse>(`/api/user_storages/${userId}/lots/`, {
        params: {
          page: page + 1,
          page_size: perPage,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getUserStorages = createAsyncThunk<IUserStoragesResponse['data'], PaginationType & TUserStorageParams>('getUserStorages',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<IUserStoragesResponse>('/api/user_storages/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWarehouses = createAsyncThunk<IStorageWarehouseResponse['data'], PaginationType & TWarehouseParams>('getWarehouses',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<IStorageWarehouseResponse>('/api/warehouse_locations/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteWarehouse = createAsyncThunk<void, number[]>('deleteWarehouse',
  async (ids, thunkAPI) => {
    try {
      await thunkAPI.extra.post<void>('/api/warehouse_locations/list_remove/', { objects: ids });
      return undefined;
    } catch (err) {
      return thunkAPI.rejectWithValue({});
    }
  });

export const postWarehouse = createAsyncThunk<IWarehouse, string>('postWarehouse',
  async (name, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<IPostWarehouseResponse>('/api/warehouse_locations/', { name });
      thunkAPI.dispatch(successMessageAction("Warehouse has been created successfully"));
      return {
        ...response.data.data,
        lots_in_storage_num: 0,
      };
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Server Error"));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getWarehouseDetails = createAsyncThunk<IStorageWarehouseDetailsResponse['data'], number>('getWarehouseDetails',
  async (warehouseId, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<IStorageWarehouseDetailsResponse>(`/api/warehouse_locations/${warehouseId}/`);
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWarehouseLots = createAsyncThunk<IStorageWarehouseLotsResponse['data'], PaginationType & TWarehouseLotsParams>('getWarehouseLots',
  async ({
    page,
    perPage,
    params,
    warehouseId,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<IStorageWarehouseLotsResponse>(`/api/warehouse_locations/${warehouseId}/lots/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const postUserStorageReminder = createAsyncThunk<void, number[]>('postUserStorageReminder',
  async (ids, thunkAPI) => {
    try {
      await thunkAPI.extra.post<void>('/api/user_storages/send_reminders/', { objects: ids });
      thunkAPI.dispatch(successMessageAction("Reminder has been sent successfully"));
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Can't send reminder"));
      return thunkAPI.rejectWithValue({});
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getUserStorageLots.pending, (state) => ({
    ...state,
    userStorageLots: {
      ...state.userStorageLots,
      ...initialErrorsState,
      isLoading: true,
    },
  }));

  builder.addCase(getUserStorageLots.fulfilled, (state, { payload, meta }) => ({
    ...state,
    userStorageLots: {
      ...state.userStorageLots,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getUserStorageLots.rejected, (state, { payload }) => ({
    ...state,
    userStorageLots: {
      ...state.userStorageLots,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(getUserStorages.pending, (state) => ({
    ...state,
    userStorages: {
      ...state.userStorages,
      ...initialErrorsState,
      isLoading: true,
    },
  }));
  builder.addCase(getUserStorages.fulfilled, (state, { payload, meta }) => ({
    ...state,
    userStorages: {
      ...state.userStorages,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getUserStorages.rejected, (state, { payload }) => ({
    ...state,
    userStorages: {
      ...state.userStorages,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(getWarehouses.pending, (state) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      ...initialErrorsState,
      isLoading: true,
    },
  }));
  builder.addCase(getWarehouses.fulfilled, (state, { payload, meta }) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getWarehouses.rejected, (state, { payload }) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(getWarehouseLots.pending, (state) => ({
    ...state,
    warehouseLots: {
      ...state.warehouseLots,
      ...initialErrorsState,
      isLoading: true,
    },
  }));
  builder.addCase(getWarehouseLots.fulfilled, (state, { payload, meta }) => ({
    ...state,
    warehouseLots: {
      ...state.warehouseLots,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getWarehouseLots.rejected, (state, { payload }) => ({
    ...state,
    warehouseLots: {
      ...state.warehouseLots,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(getWarehouseDetails.pending, (state) => ({
    ...state,
    warehouseDetails: {
      ...state.warehouseDetails,
      name: '',
    },
  }));
  builder.addCase(getWarehouseDetails.fulfilled, (state, { payload: warehouseDetails }) => ({
    ...state,
    warehouseDetails,
  }));

  builder.addCase(postWarehouse.fulfilled, (state, { payload }) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      result: [
        ...state.warehouses.result,
        payload,
      ],
    },
  }));
});

export const selectUserStorageLotsData = createSelector(
  (state: RootState) => state.storage,
  (storage) => storage.userStorageLots,
);

export const selectUserStoragesData = createSelector(
  (state: RootState) => state.storage,
  (storage) => storage.userStorages,
);

export const selectWarehousesData = createSelector(
  (state: RootState) => state.storage,
  (storage) => storage.warehouses,
);

export const selectWarehousesLotsData = createSelector(
  (state: RootState) => state.storage,
  (storage) => storage.warehouseLots,
);

export const selectWarehouseDetails = createSelector(
  (state: RootState) => state.storage,
  (storage) => storage.warehouseDetails,
);
