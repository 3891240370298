import React, { useEffect, useState } from 'react';
import {
  Container, Typography, TextField, Button,
  Grid, Link as UiLink, CircularProgress, Card, InputAdornment, IconButton, Theme,
} from '@mui/material';
import { cyan } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Link, useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { addServerErrors, validEmailRegex } from '../../../utilities';
import { useAppDispatch } from '../../../redux/store';
import { login as loginAction, selectLoadingStatus, selectLoginError } from '../../../redux/ducks/auth/auth';
import { ErrorsList } from '../../atoms/ErrorsList';
import { Inputs } from './SingInPage.types';
import routes from '../../../routes';
import { passwordRules } from '../../../validationRules';

const useStyles = makeStyles((theme:Theme) => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    position: 'relative',
    margin: theme.spacing(0, 0, 2),
  },
  buttonProgress: {
    color: cyan[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const emailRules = {
  required: 'Please, specify your email',
  pattern: {
    value: validEmailRegex,
    message: 'Invalid email address',
  },
};

const SignInPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [serverErrors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const loginError = useSelector(selectLoginError);
  const isLoading = useSelector(selectLoadingStatus);
  const {
    control, handleSubmit, getValues, setError,
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const submit = async () => {
    const result = await dispatch(loginAction(getValues()));
    if (loginAction.fulfilled.match(result)) {
      setErrors({});
      history.push(routes.home);
    }
  };

  useEffect(() => {
    setErrors({});
    if (loginError) {
      setErrors(addServerErrors(loginError, setError, {
        email: '',
        password: '',
      }));
    }
    return () => {
      setErrors({});
    };
  }, [loginError]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="div" maxWidth="xs">
      <Card className={classes.card}>
        <div className={classes.paper}>
          <Typography className={classes.title} component="h1" variant="h5">
            Sign In
          </Typography>
          <form onSubmit={handleSubmit(submit)} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={emailRules}
                  name="email"
                  render={({
                    field: { onChange, value, ref },
                    fieldState,
                  }) => (
                    <TextField
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      id="email"
                      label="Email Address"
                      autoComplete="email"
                      placeholder="Enter your email"
                      autoFocus
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  rules={passwordRules}
                  name="password"
                  render={({
                    field: { onChange, value, ref },
                    fieldState,
                  }) => (
                    <TextField
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={!!fieldState.error?.message}
                      helperText={fieldState.error?.message}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      placeholder="Enter your password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {serverErrors && (<ErrorsList errors={serverErrors} />)}
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                >
                  Sign In
                  {isLoading && (<CircularProgress size={24} color="secondary" className={classes.buttonProgress} />)}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs justifyContent="flex-end">
                <Link to={routes.forgotPassword}>
                  <UiLink component="button" variant="body2">
                    Forgot password?
                  </UiLink>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Card>
    </Container>
  );
};

export default SignInPage;
