import React, { FC, useEffect, useState } from 'react';
import {
  Button, Card, CircularProgress, Grid, TextField, Theme, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { cyan } from '@mui/material/colors';
import { Inputs, Props } from './ResetPasswordStep2.types';
import { addServerErrors } from '../../../utilities';
import {
  forgetPasswordValidateToken,
  selectForgetEmailErrors,
  selectLoadingStatus,
} from '../../../redux/ducks/forgetPassword/forgetPassword';
import { useAppDispatch } from '../../../redux/store';
import { ErrorsList } from '../../atoms/ErrorsList';

const useStyles = makeStyles((theme:Theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  button: {
    position: 'relative',
    margin: theme.spacing(0, 0, 0),
  },
  buttonProgress: {
    color: cyan[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  [theme.breakpoints.down('sm')]: {
    buttons: {
      marginTop: theme.spacing(0),
    },
    button: {
      margin: theme.spacing(0),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const tokenRules = {
  required: 'This field is required',
  minLength: {
    value: 8,
    message: 'Min length is 8',
  },
  maxLength: {
    value: 100,
    message: 'max length is 100',
  },
};

const ResetPasswordStep2: FC<Props> = ({
  onSubmitStep,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [serverErrors, setErrors] = useState({});
  const forgetError = useSelector(selectForgetEmailErrors);
  const isLoading = useSelector(selectLoadingStatus);

  const {
    control, handleSubmit, setError,
  } = useForm<Inputs>({
    defaultValues: {
      token: '',
    },
  });

  const submitForm = async (data:Inputs) => {
    const result = await dispatch(forgetPasswordValidateToken(data));
    if (forgetPasswordValidateToken.fulfilled.match(result)) {
      onSubmitStep(3);
      setErrors({});
    }
  };

  const goBack = () => {
    onSubmitStep(1);
  };

  useEffect(() => {
    setErrors({});
    if (forgetError) {
      setErrors(addServerErrors(forgetError, setError, { token: '' }));
    }
  }, [forgetError]);

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component="h1" variant="h5">
        Email code
      </Typography>
      <form onSubmit={handleSubmit(submitForm)} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={tokenRules}
              name="token"
              render={({
                field: { onChange, value, ref },
                fieldState,
              }) => (
                <TextField
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  id="token"
                  label="Email code"
                  autoComplete="token"
                  autoFocus
                  placeholder="Enter your code"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

        </Grid>
        <Grid container spacing={2} className={classes.buttons}>
          <Grid item xs={12}>
            {serverErrors && (<ErrorsList errors={serverErrors} />)}
          </Grid>
          <Grid container item xs={12} md={6}>
            <Button
              type="button"
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={goBack}
              className={classes.button}
              size="large"
            >
              Go back
            </Button>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              className={classes.button}
              size="large"
            >
              Submit
              {isLoading && (<CircularProgress size={24} color="secondary" className={classes.buttonProgress} />)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default ResetPasswordStep2;
