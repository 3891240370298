import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { paymentContent, tablePaginationParams } from '../../../staticData';
import { PaymentsState } from './paymentMethods.types';
import readablePaymentMethod from './utils';

const initialErrorsState = {
  errors: null,
};

const initialState: PaymentsState = {
  result: [],
  isLoading: false,
  payment: null,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('paymentMethods');

export const getUserPaymentsAction = createAsyncThunk<any, any>('getUserPaymentsAction',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>(`/api/users/${id}/payment_method/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createPaymentMethod = createAsyncThunk<any, any>('createPaymentMethod',
  async ({ data }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<any, any>('/api/payment_methods/', {
        ...data,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editPaymentMethod = createAsyncThunk<any, any>('editPaymentMethod',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<any, any>(`/api/payment_methods/${id}/`, {
        ...data,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetPayments = createAction('resetPayments');

export default createReducer(initialState, (builder) => {
  builder.addCase(getUserPaymentsAction.fulfilled, (state, {
    payload,
  }) => ({
    ...state,
    ...initialErrorsState,
    payment: payload.data.results[0],
    isLoading: false,
  }));
  builder.addCase(getUserPaymentsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getUserPaymentsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(createPaymentMethod.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    payment: payload.data,
    isLoading: false,
  }));
  builder.addCase(createPaymentMethod.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createPaymentMethod.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editPaymentMethod.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    payment: payload.data,
    isLoading: false,
  }));
  builder.addCase(editPaymentMethod.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editPaymentMethod.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetPayments, (state) => ({
    ...state,
    ...initialErrorsState,
    payment: initialState.payment,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.paymentMethods.isLoading,
  (isLoading) => isLoading,
);

export const selectPaymentErrors = createSelector(
  (state: RootState) => state.paymentMethods.errors,
  (errors) => errors,
);

export const selectPayment = createSelector(
  (state: RootState) => state.paymentMethods.payment,
  (payment) => payment,
);

export const selectPaymentId = createSelector(
  (state: RootState) => state.paymentMethods.payment,
  (payment) => payment?.id,
);

export const selectFormattedPayment = createSelector(
  (state: RootState) => state.paymentMethods.payment,
  (payment) => payment && [
    {
      title: 'Payment Method',
      name: readablePaymentMethod[payment.method],
    },
    ...Object.entries({
      ...paymentContent?.[payment.method],
    }).map((item:any) => ({
      title: item[1],
      name: payment?.details?.[item[0]],
    })),
  ],
);
