import { stringify } from 'qs';
import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  BottlerPatchType,
  BottlerState, BottlerType, EditBottlerResponse, EditBottlerType,
  GetBottlerResponse,
  GetBottlersResponse,
  GetBottlersType,
  GetBottlerType,
  PostBottlerResponse,
  PostBottlerType,
} from './itemBottler.types';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: BottlerState = {
  result: [],
  bottler: undefined,
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('itemBottler');

export const getBottlersAction = createAsyncThunk<GetBottlersType, PaginationType & any>('getBottlers',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetBottlersResponse>('/api/item_bottler/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getBottlerAction = createAsyncThunk<GetBottlerType, { id: number }>('getBottler',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id: number }, GetBottlerResponse>(`/api/item_bottler/${id}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const createBottlerAction = createAsyncThunk<PostBottlerType, BottlerType>('createBottlerAction',
  async ({ name }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<BottlerType, PostBottlerResponse>('/api/item_bottler/', {
        name,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const editBottlerAction = createAsyncThunk<EditBottlerType, BottlerPatchType>('editBottlerAction',
  async ({ id, name }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<BottlerType, EditBottlerResponse>(`/api/item_bottler/${id}/`, {
        name,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const deleteBottlerAction = createAsyncThunk<null, { id?: number }>('deleteBottler',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/item_bottler/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkDeleteBottlerAction = createAsyncThunk<null, number[]>('bulkDeleteBottlerAction',
  async (ids, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/item_bottler/`, {
        headers: {
          'X-BULK-OPERATION': 'true',
        },
        params: {
          id: ids,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(createBottlerAction.fulfilled, (state, action) => {
    const bottlers = state.result.map((bottler) => {
      if (bottler.id === action.payload.data.id) {
        return action.payload.data;
      }
      return bottler;
    });

    return ({
      ...state,
      ...initialErrorsState,
      bottler: action.payload.data,
      result: [...bottlers, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createBottlerAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createBottlerAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editBottlerAction.fulfilled, (state, action) => {
    const bottlers = state.result.map((bottler) => {
      if (bottler.id === action.payload.data.id) {
        return action.payload.data;
      }
      return bottler;
    });

    return ({
      ...state,
      ...initialErrorsState,
      bottler: action.payload.data,
      result: bottlers,
      isLoading: false,
    });
  });
  builder.addCase(editBottlerAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editBottlerAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getBottlersAction.fulfilled, (state, {
    payload,
    meta,
  }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getBottlersAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getBottlersAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getBottlerAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    bottler: payload.data,
    isLoading: false,
  }));
  builder.addCase(getBottlerAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getBottlerAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteBottlerAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteBottlerAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteBottlerAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteBottlerAction.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteBottlerAction.fulfilled, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteBottlerAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.itemBottler.isLoading,
  (id) => id,
);

export const selectBottlers = createSelector(
  (state: RootState) => state.itemBottler.result,
  (id) => id,
);

export const selectBottler = createSelector(
  (state: RootState) => state.itemBottler.bottler,
  (id) => id,
);

export const selectBottlersCount = createSelector(
  (state: RootState) => state.itemBottler.count,
  (id) => id,
);

export const selectBottlersPage = createSelector(
  (state: RootState) => state.itemBottler.page,
  (id) => id,
);

export const selectBottlersPerPage = createSelector(
  (state: RootState) => state.itemBottler.perPage,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.itemBottler.errors,
  (id) => id,
);
