import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  ListItem, ListItemIcon, ListItemText, Collapse, List, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Props } from './CollapseList.types';
import { selectCurrentUser } from '../../../redux/ducks/users/users';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  link: {
    width: '100%',
  },
  linkActive: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const CollapseList: FC<Props> = ({
  title, links, onLink, icon, activeRoute,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const user = useSelector(selectCurrentUser);
  const isSuperUser = user?.is_superuser;

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const isCollapseActive = links.some(({ link }) => link === `/${activeRoute}`);
    setOpen(isCollapseActive);
  }, [activeRoute]);
  return (
    <>
      <ListItem button onClick={() => handleClick()}>
        <ListItemIcon>
          {icon as ReactNode}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            links.map(({ link, name, onlySuperUser }) => (
              !onlySuperUser || onlySuperUser === isSuperUser ? (
                <ListItem
                  button
                  component={NavLink}
                  to={link}
                  className={cn(classes.link, { [classes.linkActive]: link === `/${activeRoute}` })}
                  onClick={onLink(false)}
                  exact
                  key={link}
                >
                  <ListItemText primary={name} className={classes.nested} />
                </ListItem>
              ) : null))
          }
        </List>
      </Collapse>
    </>
  );
};

export default CollapseList;
