import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import rootReducer from './rootReducer';
import instanceAxios from '../axiosInstance';

const customizableMiddleware = getDefaultMiddleware({
  thunk: {
    extraArgument: instanceAxios,
  },
  serializableCheck: {
    ignoredPaths: ['ui'],
    ignoredActionPaths: ['meta.arg', 'payload.callback'],
  },
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: customizableMiddleware,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
