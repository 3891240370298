import CssBaseline from '@mui/material/CssBaseline';
import {
  Tooltip, Snackbar, Alert, ToggleButton, createTheme, Theme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import Brightness3 from '@mui/icons-material/Brightness3';
import Brightness7 from '@mui/icons-material/Brightness7';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { useAppDispatch } from './redux/store';
import {
  selectDarkMode, selectSnackbar, setDarkMode, setSnackbarOpen,
} from './redux/ducks/ui/ui';
import Router from './Router';
import ModalsWrap from './components/molecules/ModalsWrap';
import { LocalizationProviderWrapper } from './components/atoms/LocalizationProviderWrapper';
import { Transition } from './components/atoms/Transition';

const themeOptions = (mode: 'dark'|'light') => ({
  palette: {
    mode,
    primary: {
      main: '#b98e61',
      contrastText: 'rgb(255,255,255)',
    },
    secondary: {
      main: '#ff5722',
      contrastText: '#fff',
    },
    backgroundColor: {
      disabled: 'red',
    },
    info: {
      main: '#e0e0e0',
      dark: '#ffffff',
      contrastText: '#000',
    },
    text: {
      disabled: '#bebebe',
    },
    action: {
      disabled: '#bebebe',
    },
    inherit: {
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          background: '$labelcolor',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1366,
      xl: 1920,
    },
  },
  zIndex: {
    snackbar: 2100,
    tooltip: 2200,
    modal: 2002,
  },
});
const useStyles = makeStyles((theme:Theme) => ({
  switchContainer: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    top: '10px',
    right: '10px',
    zIndex: 2001,
    [theme.breakpoints.up('sm')]: {
      top: '14px',
    },
  },
  alert: {
    whiteSpace: 'pre-line',
  },
}));

const withThemeProvider = (Component: any) => {
  const WithThemeProvider = (props: any) => {
    const isDark = useSelector(selectDarkMode);
    const palette = themeOptions(isDark ? 'dark' : 'light');
    const theme = createTheme(palette);

    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    );
  };

  return WithThemeProvider;
};

const AppThemeProvider = () => {
  const cookies = new Cookies();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isDarkCookies = Boolean(cookies.get('darkMode', { doNotParse: false }));
  const snackBar = useSelector(selectSnackbar);
  const isDark = useSelector(selectDarkMode);

  const toggleDarkMode = () => {
    dispatch(setDarkMode(!isDark));
  };

  useEffect(() => {
    if (isDarkCookies !== isDark) {
      dispatch(setDarkMode(isDarkCookies));
    }
  }, [isDark]);

  const handleClose = () => {
    dispatch(setSnackbarOpen({
      isOpen: false,
      message: '',
      severity: 'info',
    }));
  };

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Snackbar
        open={snackBar.isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Alert onClose={handleClose} severity={snackBar.severity} className={classes.alert}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <ModalsWrap />
      <div className={classes.switchContainer}>
        <Tooltip title="Light/Night mode">
          <ToggleButton
            value="check"
            selected={isDark}
            onChange={toggleDarkMode}
            size="small"
          >
            {isDark ? <Brightness7 fontSize="small" color="inherit" /> : <Brightness3 fontSize="small" color="inherit" /> }
          </ToggleButton>
        </Tooltip>
      </div>
      <LocalizationProviderWrapper>
        <Router />
      </LocalizationProviderWrapper>
    </StyledEngineProvider>
  );
};

export default withThemeProvider(AppThemeProvider);
