import { stringify } from 'qs';
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';
import {
  EditOrderStatus, EditOrderStatusesType, EditOrderStatusResponse,
  GetOrderStatusesResponse,
  GetOrderStatusesType,
  GetOrderStatusResponse,
  GetOrderStatusType, OrderStatusesState, PostOrderStatus, PostOrderStatusResponse,
} from './orderStatuses.types';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: OrderStatusesState = {
  result: [],
  status: {
    email_template: '',
    name: '',
    color: '',
    can_be_downloaded: false,
    send_email_on_change: false,
    is_reserved: false,
  },
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('orderStatuses');

export const getOrderStatuses = createAsyncThunk<GetOrderStatusesType, PaginationType & any>('getOrderStatuses',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetOrderStatusesResponse>('/api/order_statuses/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const getOrderStatus = createAsyncThunk<GetOrderStatusType, { id?: number }>('getOrderStatus',
  async ({
    id,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id?: number }, GetOrderStatusResponse>(`/api/order_statuses/${id}/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editOrderStatusAction = createAsyncThunk<EditOrderStatus, { id: number, data: EditOrderStatusesType }>('editOrderStatusAction',
  async ({
    id, data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<EditOrderStatusesType, EditOrderStatusResponse>(`/api/order_statuses/${id}/`, data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createOrderStatusAction = createAsyncThunk<PostOrderStatus, { data: OrderStatusesState }>('createOrderStatusAction',
  async ({
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<OrderStatusesState, PostOrderStatusResponse>('/api/order_statuses/', data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteOrderStatusAction = createAsyncThunk<null, { id?: number }>('deleteOrderStatusAction',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/order_statuses/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkDeleteOrderStatusAction = createAsyncThunk<null, number[]>('bulkDeleteOrderStatusAction',
  async (ids, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/order_statuses/`, {
        headers: {
          'X-BULK-OPERATION': 'true',
        },
        params: {
          id: ids,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetOrderStatusesForm = createAction('resetOrderStatusesForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(getOrderStatuses.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getOrderStatuses.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOrderStatuses.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getOrderStatus.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    status: payload.data,
    isLoading: false,
  }));
  builder.addCase(getOrderStatus.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getOrderStatus.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editOrderStatusAction.fulfilled, (state, action) => {
    const statuses = state.result.map((status) => {
      if (status.id === action.payload.data.id) {
        return action.payload.data;
      }
      return status;
    });

    return ({
      ...state,
      ...initialErrorsState,
      status: action.payload.data,
      result: statuses,
      isLoading: false,
    });
  });
  builder.addCase(editOrderStatusAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editOrderStatusAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(createOrderStatusAction.fulfilled, (state, action) => {
    const statuses = state.result.map((status) => {
      if (status.id === action.payload.data.id) {
        return action.payload.data;
      }
      return status;
    });

    return ({
      ...state,
      ...initialErrorsState,
      status: action.payload.data,
      result: statuses,
      isLoading: false,
    });
  });
  builder.addCase(createOrderStatusAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createOrderStatusAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(deleteOrderStatusAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteOrderStatusAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteOrderStatusAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(resetOrderStatusesForm, (state) => ({
    ...state,
    ...initialErrorsState,
    status: initialState.status,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.orderStatuses.isLoading,
  (id) => id,
);

export const selectOrderStatuses = createSelector(
  (state: RootState) => state.orderStatuses.result,
  (result) => result,
);

export const selectOrderStatusesNames = createSelector(
  (state: RootState) => state.orderStatuses.result,
  (result) => result.map((item) => ({
    value: item.name,
    text: item.name,
  })),
);

export const selectOrderStatus = createSelector(
  (state: RootState) => state.orderStatuses.status,
  (result) => result,
);

export const selectOrderStatusCount = createSelector(
  (state: RootState) => state.orderStatuses.count,
  (count) => count,
);

export const selectOrderStatusPage = createSelector(
  (state: RootState) => state.orderStatuses.page,
  (page) => page,
);

export const selectOrderStatusPerPage = createSelector(
  (state: RootState) => state.orderStatuses.perPage,
  (page) => page,
);

export const selectOrderStatusErrors = createSelector(
  (state: RootState) => state.orderStatuses.errors,
  (page) => page,
);
