import {
  createAction,
  createReducer,
  createSelector,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { InitialState, ModalInfo, Snackbar } from './ui.types';
import { RootState } from '../../rootReducer';
import { getErrorText } from '../../apiTypes/shared/errors';

const initialState: InitialState = {
  darkMode: false,
  menuOpen: false,
  isBackUnAvailable: false,
  snackbar: {
    isOpen: false,
    message: '',
    severity: 'info',
  },
  currentModal: {
    title: '',
    description: '',
    modal: 'closed',
    callback: () => null,
  },
};

export const setDarkMode = createAction<boolean>('setDarkMode');
export const setMenuOpen = createAction<boolean>('setMenuOpen');
export const setBackAvailable = createAction<boolean>('setBackAvailable');
export const setSnackbarOpen = createAction<Snackbar>('setSnackbarOpen');
export const setCurrentModal = createAction<ModalInfo>('setCurrentModal');

export const successMessageAction = (message: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setSnackbarOpen({ isOpen: true, severity: "success", message }));
};

export const errorMessageAction = (message: string) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setSnackbarOpen({ isOpen: true, severity: "error", message }));
};

export const dispatchErrorMessage = (error: AxiosError<any>) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setSnackbarOpen({ isOpen: true, severity: "error", message: getErrorText(error) }));
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setDarkMode, (state, { payload }) => {
    const cookies = new Cookies();
    cookies.set('darkMode', payload, { path: '/' });
    return ({
      ...state,
      darkMode: payload,
    });
  });
  builder.addCase(setMenuOpen, (state, { payload }) => ({
    ...state,
    menuOpen: payload,
  }));
  builder.addCase(setSnackbarOpen, (state, { payload }) => ({
    ...state,
    snackbar: payload,
  }));
  builder.addCase(setCurrentModal, (state, { payload }) => ({
    ...state,
    currentModal: payload,
  }));
  builder.addCase(setBackAvailable, (state, { payload }) => ({
    ...state,
    isBackUnAvailable: payload,
  }));
});

export const selectDarkMode = createSelector(
  (state: RootState) => state.ui.darkMode,
  (id) => id,
);

export const selectMenuOpen = createSelector(
  (state: RootState) => state.ui.menuOpen,
  (id) => id,
);

export const selectSnackbar = createSelector(
  (state: RootState) => state.ui.snackbar,
  (id) => id,
);

export const selectCurrentModal = createSelector(
  (state: RootState) => state.ui.currentModal,
  (id) => id,
);

export const selectIsBackUnAvailable = createSelector(
  (state: RootState) => state.ui.isBackUnAvailable,
  (id) => id,
);
