import React, { FC } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { selectCurrentModal, setSnackbarOpen } from '../../../redux/ducks/ui/ui';
import { useAppDispatch } from '../../../redux/store';
import { Props } from './ConfirmModal.types';
import { Transition } from '../../atoms/Transition';

const useStyles = makeStyles(() => ({
  root: {
    'z-index': '2003 !important',
  },
  paper: {
    width: '400px',
  },
}));

const ConfirmModal: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const currentModal = useSelector(selectCurrentModal);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onClose();
    if (currentModal?.onCancelCb) currentModal.onCancelCb();
  };
  const handleAgree = async () => {
    const callback = currentModal?.callback;
    onClose();

    if (callback) {
      const responseCallback = await callback();
      if (responseCallback && responseCallback?.message.length > 0) {
        dispatch(setSnackbarOpen({
          isOpen: true,
          message: responseCallback?.message,
          severity: responseCallback?.errors,
        }));
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" component="div">
          {currentModal?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {currentModal?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAgree}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
