import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { setBackAvailable } from '../../../redux/ducks/ui/ui';
import { useAppDispatch } from '../../../redux/store';
import { Props } from './RouteLeavingGuard.types';

const useStyles = makeStyles(() => ({
  root: {
    'z-index': '2003 !important',
  },
  paper: {
    width: '400px',
  },
}));

export const RouteLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
}:Props) => {
  const classes = useStyles();
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<{ pathname:string }| undefined>(undefined);
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
  const dispatch = useAppDispatch();

  const showModal = (location:{ pathname:string }) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb?:()=>void) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation:{ pathname:string }) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => when;
    } else {
      window.onbeforeunload = () => null;
    }
  }, [when]);

  const handleConfirmNavigationClick = () => {
    dispatch(setBackAvailable(false));
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      if (lastLocation) {
        navigate(lastLocation.pathname);
      }
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={(location) => handleBlockedNavigation(location)} />
      <Dialog
        open={modalVisible}
        onClose={() => closeModal()}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" component="div">
            Close the page?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The changes will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button onClick={handleConfirmNavigationClick}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RouteLeavingGuard;
