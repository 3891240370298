import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { Error } from './apiTypes/shared/errors';
import { RootState } from './rootReducer';

export type PayloadCreator<R, T> = AsyncThunkPayloadCreator<R, T, {
  extra: AxiosInstance;
  rejectValue: Error;
  state: RootState,
}>

const enhancedAsyncThunkCreator = (duckType: string) => <R, T>(type: string, func: PayloadCreator<R, T>) => createAsyncThunk(`${duckType}/${type}`, func);

export default enhancedAsyncThunkCreator;
