import React, { FC } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';
import styles from './ErrorsList.module.scss';
import { Props } from './ErrorsList.types';

const ErrorsList:FC<Props> = ({ errors }) => {
  const errorsArray = (Object.keys(errors) as Array<keyof typeof errors>);
  return (
    <ul className={cn(styles.errorsWrap, { [styles.withErrors]: errorsArray.length > 0 })}>
      {errorsArray.map((key) => (
        <li className={styles.textError} key={key}>
          <Typography className={styles.text} variant="caption" color="error">{errors?.[key]}</Typography>
        </li>
      ))}
    </ul>
  );
};

export default ErrorsList;
