import React, { useState } from 'react';
import { Container, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ResetPasswordStep1 } from '../../organisms/ResetPasswordStep1';
import { ResetPasswordStep2 } from '../../organisms/ResetPasswordStep2';
import { ResetPasswordStep3 } from '../../organisms/ResetPasswordStep3';

const useStyles = makeStyles((theme:Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(1);

  const submit = (step:number) => {
    setCurrentStep(step);
  };

  return (
    <Container component="div" maxWidth="xs">
      <div className={classes.paper}>
        {currentStep === 1 && (<ResetPasswordStep1 onSubmitStep={submit} />)}
        {currentStep === 2 && (<ResetPasswordStep2 onSubmitStep={submit} />)}
        {currentStep === 3 && (<ResetPasswordStep3 onSubmitStep={submit} />)}
      </div>
    </Container>
  );
};

export default ResetPasswordPage;
