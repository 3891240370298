import React, { Ref } from 'react';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((
  props: TransitionProps & { children: any },
  ref: Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />);

export default Transition;
