import React, { FC } from 'react';
import { Button, SwipeableDrawer, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from '@mui/styles';
import { red } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import { Props } from './SideBar.types';
import { useAppDispatch } from '../../../redux/store';
import {
  selectIsBackUnAvailable, selectMenuOpen, setBackAvailable, setCurrentModal, setMenuOpen,
} from '../../../redux/ducks/ui/ui';
import NavList from '../NavList';
import { authLogOut } from '../../../redux/ducks/auth/auth';
import routes from '../../../routes';

const useStyles = makeStyles((theme:Theme) => ({
  logOut: {
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: red[600],
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: red[800],
    },
  },
  root: {
    'z-index': '2001 !important',
  },
  buttonWrap: {
    padding: theme.spacing(0, 2, 0, 2),
    paddingTop: theme.spacing(3),
    width: '100%',
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

const SideBar: FC<Props> = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const isMenuOpen = useSelector(selectMenuOpen);
  const isBackUnAvailable = useSelector(selectIsBackUnAvailable);
  const history = useHistory();

  const toggleDrawer = (state: boolean) => () => {
    dispatch(setMenuOpen(state));
  };

  const onAuthLogOut = () => {
    dispatch(setCurrentModal({
      title: 'Logout from the system?',
      description: (
        <span>
          You will be log out from the system.
          <br />
          {' '}
          {isBackUnAvailable ? <strong>Your data will be lost !</strong> : ''}
        </span>
      ),
      callback: () => {
        dispatch(setBackAvailable(false));
        dispatch(authLogOut());
        history.push(routes.login);
      },
      modal: 'confirmModal',
    }));
    toggleDrawer(false)();
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={isMenuOpen}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      classes={{
        modal: classes.root,
      }}
    >
      <div className={classes.wrapper}>
        <NavList onLink={toggleDrawer} />
        <div className={classes.buttonWrap}>

          <Button
            className={classes.logOut}
            type="button"
            variant="text"
            size="medium"
            color={'info' as 'inherit'}
            startIcon={<ExitToAppIcon fontSize="small" />}
            onClick={onAuthLogOut}
          >
            LOG OUT
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
};
export default SideBar;
