import React, { FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { ProtectedRouteProps } from './ProtectedRoute.types';
import { selectTokens } from '../../../redux/ducks/auth/auth';
import routes from '../../../routes';
import { Page } from '../Page';
import { useAppDispatch } from '../../../redux/store';
import {
  getCurrentUserAction,
  resetCurrentUser,
  selectCurrentUser,
} from '../../../redux/ducks/users/users';

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const {
    path, exact, component: Component, title, SuperUserRoute = false, isPrivate = true,
  } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const isSuperUser = user?.is_superuser;
  const isTokens = useSelector(selectTokens);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('access');
    const isAuthenticated = [!token, !isTokens.access_token].some((el) => !el);
    if (!isAuthenticated && isPrivate) {
      history.push(routes.login);
    } else if (!isPrivate) {
      history.push(routes.home);
    }

    dispatch(getCurrentUserAction()).then(() => {
      if (isPrivate && !isAuthenticated) {
        history.push(routes.login);
        dispatch(resetCurrentUser());
      } else if (!isPrivate) {
        history.push(routes.home);
      }
    });
  }, []);

  useEffect(() => {
    const conditions = [routes.forgotPassword, routes.login];
    const cookies = new Cookies();
    const token = cookies.get('access');
    const isAuthenticated = [!token, !isTokens.access_token].some((el) => !el);
    if (!isAuthenticated) {
      if (!conditions.some(((el) => history.location.pathname.includes(el)))) {
        history.push(routes.login);
      }
    }
  }, [isTokens]);

  return (
    <Route
      path={path}
      exact={exact}
      render={(restProps) => {
        if (isSuperUser) {
          return (
            <Page title={title}>
              <Component {...restProps} />
            </Page>
          );
        }
        if (SuperUserRoute && !isSuperUser) {
          return <Redirect to={{ pathname: routes.home, search: `redirect=${history.location.pathname}` }} />;
        }

        return (
          <Page title={title}>
            <Component {...restProps} />
          </Page>
        );
      }}
    />
  );
};

export default ProtectedRoute;
