import { createReducer, createSelector } from '@reduxjs/toolkit';
import { OptionsType } from '../../../components/molecules/Filters/Filters';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { errorMessageAction } from '../ui/ui';
import { ICountriesState, IDivisionDataItem, IDivisionResponse } from './countries.types';

const initialErrorsState = {
  errors: null,
};

const initialState: ICountriesState = {
  result: [],
  regions: [],
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('countries');

export const getCountries = createAsyncThunk<any, void>('getCountries',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<void, any>('/api/countries/');
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getDivisions = createAsyncThunk<
  IDivisionDataItem[],
  { countrySlug: string, country: string }
>("getDivisions", async ({ countrySlug }, thunkAPI) => {
  try {
    const response = await thunkAPI.extra.get<IDivisionResponse>(
      `/api/divisions/${countrySlug}/`,
    );
    return response.data.data;
  } catch (err) {
    thunkAPI.dispatch(errorMessageAction("Regions loading failure"));
    return thunkAPI.rejectWithValue({});
  }
});

export default createReducer(initialState, (builder) => {
  builder.addCase(getCountries.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data,
    isLoading: false,
  }));
  builder.addCase(getCountries.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getCountries.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getDivisions.fulfilled, (state, { payload, meta }) => ({
    ...state,
    isLoading: false,
    regions: {
      ...state.regions,
      [meta.arg.country]: payload,
    },
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.countries.isLoading,
  (isLoading) => isLoading,
);

export const selectCountries = createSelector(
  (state: RootState) => state.countries.result,
  (result) => result,
);
export const selectCountriesFormatted = createSelector(
  (state: RootState) => state.countries.result
    ?.map((item: any) => ({ value: item.name, text: item.name })),
  (result) => result,
);

export const selectCountriesErrors = createSelector(
  (state: RootState) => state.countries.errors,
  (errors) => errors,
);

export const selectRegionsFormatted = (country?: string) => createSelector<any, OptionsType[]>(
  (state: RootState) => state.countries.regions,
  (regions) => (regions[country || ''] || []).map((item) => ({ value: item.name, text: item.name })),
);
