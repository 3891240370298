import { createReducer, createSelector } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { dispatchErrorMessage, successMessageAction } from '../ui/ui';

import {
  ICmsSlider,
  ICmsState,
  TCmsSliderGetResponse,
  TCmsSliderMutationResponse,
} from './CMS.types';

const initialState: ICmsState = {
  homepage: {
    sliders: [],
    isLoading: false,
    error: null,
  },
};
const createAsyncThunk = createEnhancedThunk('CMS');

export const getHomepageSliders = createAsyncThunk<ICmsSlider[], void>(
  'getHomepageSliders',
  async (body, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TCmsSliderGetResponse>(
        `/api/cms/home_page/main_slider/`,
      );
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const postHomepageSlidersOrdering = createAsyncThunk<ICmsSlider[], number[]>(
  'postHomepageSlidersOrdering',
  async (id_list, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<TCmsSliderGetResponse>(
        `/api/cms/home_page/main_slider/set_ordering/`,
        {
          id_list,
        },
      );
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export const postHomepageSlider = createAsyncThunk<
  ICmsSlider,
  ICmsSlider
>('postHomepageSlider', async (body, thunkAPI) => {
  const formData = serialize(
    {
      ...body,
    },
    { dotsForObjectNotation: true, nullsAsUndefineds: true },
  );

  try {
    const response = await thunkAPI.extra.post<TCmsSliderMutationResponse>(
      `/api/cms/home_page/main_slider/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    thunkAPI.dispatch(successMessageAction('Slide was created successfully'));
    return response.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const patchHomepageSlider = createAsyncThunk<
  ICmsSlider,
  ICmsSlider
>('patchHomepageSlider', async (body, thunkAPI) => {
  console.log('body', body);
  const formData = serialize(
    {
      ...body,
    },
    { dotsForObjectNotation: true, nullsAsUndefineds: true },
  );

  try {
    const response = await thunkAPI.extra.patch<TCmsSliderMutationResponse>(
      `/api/cms/home_page/main_slider/${body.id}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    thunkAPI.dispatch(successMessageAction('Slide was edited successfully'));

    return response.data.data;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ['Server error'] },
    });
  }
});

export const deleteHomepageSlider = createAsyncThunk<ICmsSlider[], number>(
  'deleteHomepageSlider',
  async (id, thunkAPI) => {
    try {
      await thunkAPI.extra.delete<void>(
        `/api/cms/home_page/main_slider/${id}/`,
      );
      const state = thunkAPI.getState();
      const currentList = state.CMS.homepage.sliders;
      const newList = currentList.filter((slide) => slide.id !== id);

      thunkAPI.dispatch(successMessageAction('Slide was successfully deleted'));

      return newList;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  },
);

export default createReducer(initialState, (builder) => {
  builder.addCase(postHomepageSlider.pending, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: true,
    },
  }));
  builder.addCase(postHomepageSlider.fulfilled, (state, { payload }) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
      sliders: [...state.homepage.sliders, payload],
    },
  }));
  builder.addCase(postHomepageSlider.rejected, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
    },
  }));

  builder.addCase(patchHomepageSlider.pending, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: true,
    },
  }));
  builder.addCase(patchHomepageSlider.fulfilled, (state, { payload }) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
      sliders: state.homepage.sliders.map((slide) => (slide.id === payload.id ? payload : slide)),
    },
  }));
  builder.addCase(patchHomepageSlider.rejected, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
    },
  }));

  builder.addCase(deleteHomepageSlider.pending, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: true,
    },
  }));
  builder.addCase(deleteHomepageSlider.fulfilled, (state, { payload }) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
      sliders: payload,
    },
  }));
  builder.addCase(deleteHomepageSlider.rejected, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
    },
  }));

  builder.addCase(getHomepageSliders.pending, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: true,
    },
  }));
  builder.addCase(getHomepageSliders.fulfilled, (state, { payload }) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
      sliders: payload,
    },
  }));
  builder.addCase(getHomepageSliders.rejected, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
    },
  }));

  builder.addCase(postHomepageSlidersOrdering.pending, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: true,
    },
  }));
  builder.addCase(postHomepageSlidersOrdering.fulfilled, (state, { payload }) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
      sliders: payload,
    },
  }));
  builder.addCase(postHomepageSlidersOrdering.rejected, (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      isLoading: false,
    },
  }));
});

export const selectCmsSliders = createSelector(
  (state: RootState) => state.CMS.homepage.sliders,
  (sliders) => sliders,
);

export const selectCmsSlidersLoader = createSelector(
  (state: RootState) => state.CMS.homepage.isLoading,
  (sliders) => sliders,
);
