const routes = {
  home: '/',
  lots: '/lots',
  lot: '/lots/:id',
  templates: '/templates',
  template: '/templates/:id',
  auctions: '/auctions',
  auction: '/auctions/:id',
  vouchers: '/vouchers-management',
  voucher: '/vouchers-management/:id',
  sellerSheets: '/seller-sheets',
  sellerSheet: '/seller-sheets/:id',
  customersService: '/customer',
  customerService: '/customer/:id',
  order_statuses: '/order-statuses',
  order_status: '/order-statuses/:id',
  order_storages: '/order-storages',
  order_storage: '/order-storages/:id',
  orders: '/orders',
  order: '/orders/:id',
  generalSettings: '/general-settings',
  attributesAndValues: '/attributes-and-values',
  login: '/login',
  forgotPassword: '/forgot-password',
  wallet_customers: '/wallet-customers',
  wallet_transactions: '/wallet-transactions',
  shipping: '/shipping',
  sell_requests: '/sell-requests',
  cms_homepage: '/cms-homepage',
  page404: '/404',
};

export default routes;
