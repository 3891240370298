import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import {
  BidsPagination, BidState, GetBidsResponse, GetBidsType,
} from './bids.types';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: BidState = {
  result: [],
  highest_bid: {},
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('bids');

export const getBidsAction = createAsyncThunk<GetBidsType, BidsPagination &any>('getBids',
  async ({
    page, perPage, lotId, params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<BidsPagination, GetBidsResponse>('/api/bids/', {
        params: {
          page: page + 1,
          page_size: perPage,
          lot: lotId,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getBidsHighestBidAction = createAsyncThunk<GetBidsType, any>('getBidsHighestBidAction',
  async ({
    lotId,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<BidsPagination, GetBidsResponse>('/api/bids/', {
        params: {
          offset: 0,
          limit: 1,
          lot: lotId,
          ordering: '-bid',
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const deleteBidAction = createAsyncThunk<null, { id?: number }>('deleteBid',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/bids/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const reset = createAction('reset');

export default createReducer(initialState, (builder) => {
  builder.addCase(getBidsAction.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    count: payload.data.count,
    isLoading: false,
  }));
  builder.addCase(getBidsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getBidsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getBidsHighestBidAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    highest_bid: payload.data.results?.[0],
    isLoading: false,
  }));
  builder.addCase(getBidsHighestBidAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getBidsHighestBidAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteBidAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteBidAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteBidAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(reset.type, (state) => ({
    ...state,
    ...initialErrorsState,
    result: initialState.result,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.bids.isLoading,
  (id) => id,
);

export const selectBids = createSelector(
  (state: RootState) => state.bids.result,
  (id) => id,
);

export const selectBidsCount = createSelector(
  (state: RootState) => state.bids.count,
  (id) => id,
);

export const selectBidsPage = createSelector(
  (state: RootState) => state.bids.page,
  (id) => id,
);

export const selectBidsPerPage = createSelector(
  (state: RootState) => state.bids.perPage,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.bids.errors,
  (id) => id,
);

export const selectHighestBid = createSelector(
  (state: RootState) => state.bids.highest_bid,
  (id) => id,
);
