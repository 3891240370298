import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

const initialErrorsState = {
  shippingErrors: null,
  billingErrors: null,
};

const initialState: any = {
  billing: null,
  shipping: null,
  isBillingLoading: false,
  isShippingLoading: false,
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('addresses');

export const getBillingAddress = createAsyncThunk<any, any>('getBillingAddress',
  async ({ user_id }:any, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>(`/api/users/${user_id}/billing_addresses/default/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createBillingAddress = createAsyncThunk<any, any>('createBillingAddress',
  async ({
    user_id,
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post< any, any>('/api/addresses/', {
        ...data,
        addr_type: 'BILLING',
        user: user_id,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const patchBillingAddress = createAsyncThunk<any, any>('patchBillingAddress',
  async ({
    id,
    user_id,
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch< any, any>(`/api/addresses/${id}/`, {
        ...data,
        addr_type: 'BILLING',
        user: user_id,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getShippingAddress = createAsyncThunk<any, any>('getShippingAddress',
  async ({ user_id }:any, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>(`/api/users/${user_id}/shipping_addresses/default/`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const createShippingAddress = createAsyncThunk<any, any>('createShippingAddress',
  async ({
    user_id,
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post< any, any>('/api/addresses/', {
        ...data,
        addr_type: 'SHIPPING',
        user: user_id,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const patchShippingAddress = createAsyncThunk<any, any>('patchShippingAddress',
  async ({
    id,
    user_id,
    data,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch< any, any>(`/api/addresses/${id}/`, {
        ...data,
        addr_type: 'SHIPPING',
        user: user_id,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const resetBillingForm = createAction('resetBillingForm');

export default createReducer(initialState, (builder) => {
  builder.addCase(getBillingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    billing: payload.data,
    isBillingLoading: false,
  }));
  builder.addCase(getBillingAddress.pending, (state) => ({
    ...state,
    isBillingLoading: true,
  }));
  builder.addCase(getBillingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isBillingLoading: false,
  }));

  builder.addCase(createBillingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    billing: payload.data,
    isBillingLoading: false,
  }));
  builder.addCase(createBillingAddress.pending, (state) => ({
    ...state,
    isBillingLoading: true,
  }));
  builder.addCase(createBillingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    billingErrors: action?.payload?.messages,
    isBillingLoading: false,
  }));

  builder.addCase(patchBillingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    billing: payload.data,
    isBillingLoading: false,
  }));
  builder.addCase(patchBillingAddress.pending, (state) => ({
    ...state,
    isBillingLoading: true,
  }));
  builder.addCase(patchBillingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    billingErrors: action?.payload?.messages,
    isBillingLoading: false,
  }));

  builder.addCase(getShippingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    shipping: payload.data,
    isShippingLoading: false,
  }));
  builder.addCase(getShippingAddress.pending, (state) => ({
    ...state,
    isShippingLoading: true,
  }));
  builder.addCase(getShippingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isShippingLoading: false,
  }));

  builder.addCase(createShippingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    shipping: payload.data,
    isShippingLoading: false,
  }));
  builder.addCase(createShippingAddress.pending, (state) => ({
    ...state,
    isShippingLoading: true,
  }));
  builder.addCase(createShippingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    shippingErrors: action?.payload?.messages,
    isShippingLoading: false,
  }));

  builder.addCase(patchShippingAddress.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    shipping: payload.data,
    isShippingLoading: false,
  }));
  builder.addCase(patchShippingAddress.pending, (state) => ({
    ...state,
    isShippingLoading: true,
  }));
  builder.addCase(patchShippingAddress.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    shippingErrors: action?.payload?.messages,
    isShippingLoading: false,
  }));

  builder.addCase(resetBillingForm, () => ({
    ...initialState,
    ...initialErrorsState,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.addresses.isLoading,
  (id) => id,
);

export const selectBillingAddress = createSelector(
  (state: RootState) => state.addresses.billing,
  (result) => result,
);
export const selectBillingAddressLoading = createSelector(
  (state: RootState) => state.addresses.isBillingLoading,
  (result) => result,
);

export const selectBillingErrors = createSelector(
  (state: RootState) => state.addresses.billingErrors,
  (result) => result,
);

export const selectShippingAddress = createSelector(
  (state: RootState) => state.addresses.shipping,
  (result) => result,
);
export const selectShippingAddressLoading = createSelector(
  (state: RootState) => state.addresses.isShippingLoading,
  (result) => result,
);

export const selectShippingErrors = createSelector(
  (state: RootState) => state.addresses.shippingErrors,
  (result) => result,
);
