import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookies from 'universal-cookie';
import { authLogOut } from './redux/ducks/auth/auth';
import reduxStore from './redux/store';
import { setBackAvailable } from './redux/ducks/ui/ui';

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 40000,
  timeoutErrorMessage: "Request Timeout (Check your internet connection)",
  headers: { 'Content-Type': 'application/json' },
});

instanceAxios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const cookies = new Cookies();
    const token = cookies.get('access');
    const { headers } = config;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error),
);

const refreshAuthLogic = (failedRequest: AxiosError) => {
  const cookies = new Cookies();
  const token = cookies.get('refresh');
  if (failedRequest.response?.status === 403 && failedRequest.config.method === 'get') {
    reduxStore.dispatch(setBackAvailable(false));
    reduxStore.dispatch(authLogOut());
    return Promise.reject(failedRequest);
  }

  return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/refresh_token`, {
    refresh: token,
  })
    .then((tokenRefreshResponse: AxiosResponse) => {
      const { response } = failedRequest;
      cookies.set('access', tokenRefreshResponse.data.data.access, { path: '/' });
      if (response) {
        response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.data.access}`;
      }
      return Promise.resolve();
    })
    .catch((error) => {
      reduxStore.dispatch(authLogOut());
      return Promise.reject(error);
    });
};

createAuthRefreshInterceptor(instanceAxios, refreshAuthLogic, {
  statusCodes: [401, 403],
});

export default instanceAxios;
