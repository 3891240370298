import React, { FC, useEffect, useState } from 'react';
import {
  Button, CircularProgress, Grid, Link as UiLink, TextField, Typography, Card, Theme,
} from '@mui/material';
import { cyan } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Inputs, Props } from './ResetPasswordStep1.types';
import { addServerErrors, validEmailRegex } from '../../../utilities';
import { useAppDispatch } from '../../../redux/store';
import { forgetPassword as forgetPasswordAction, selectForgetPasswordErrors, selectLoadingStatus } from '../../../redux/ducks/forgetPassword/forgetPassword';
import { ErrorsList } from '../../atoms/ErrorsList';
import routes from '../../../routes';

const useStyles = makeStyles((theme:Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    position: 'relative',
    margin: theme.spacing(0, 0, 2),
  },
  card: {
    width: '100%',
    padding: theme.spacing(2),
  },
  buttonProgress: {
    color: cyan[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const emailRules = {
  required: 'Please, specify your email',
  pattern: {
    value: validEmailRegex,
    message: 'Invalid email address',
  },
};

const ResetPasswordStep1: FC<Props> = ({
  onSubmitStep,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [serverErrors, setErrors] = useState({});
  const forgetError = useSelector(selectForgetPasswordErrors);
  const isLoading = useSelector(selectLoadingStatus);
  const {
    control, handleSubmit, setError,
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });

  const submit = async (data:Inputs) => {
    const result = await dispatch(forgetPasswordAction(data));
    if (forgetPasswordAction.fulfilled.match(result)) {
      setErrors({});
      onSubmitStep(2);
    }
  };

  useEffect(() => {
    setErrors({});
    if (forgetError) {
      setErrors(addServerErrors(forgetError, setError, { email: '' }));
    }
  }, [forgetError]);

  return (
    <Card className={classes.card}>
      <Typography className={classes.title} component="h1" variant="h5">
        Forgot password?
      </Typography>
      <form onSubmit={handleSubmit(submit)} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={emailRules}
              name="email"
              render={({
                field: { onChange, value, ref },
                fieldState,
              }) => (
                <TextField
                  ref={ref}
                  onChange={onChange}
                  value={value}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  autoFocus
                  placeholder="Enter your email "
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>

            {serverErrors && (<ErrorsList errors={serverErrors} />)}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              size="large"
            >
              Submit
              {isLoading && (<CircularProgress size={24} color="secondary" className={classes.buttonProgress} />)}
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid container item xs justifyContent="flex-end">
            <Link to={routes.login}>
              <UiLink component="button" variant="body2">
                Sign In
              </UiLink>
            </Link>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default ResetPasswordStep1;
