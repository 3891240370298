import React, { FC } from 'react';
import {
  Card, Divider, Avatar, Typography, Grid, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Props } from './UserProfileLink.types';
import routes from '../../../routes';
import { selectCurrentUser } from '../../../redux/ducks/users/users';

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    width: '100%',
    maxWidth: 350,
    padding: theme.spacing(6, 2, 0, 2),
  },
  divider: {
    margin: theme.spacing(3, 0, 0),
    width: '100%',
  },
  card: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
  },
  wrap: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const UserProfileLink:FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const user = useSelector(selectCurrentUser);
  const name = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
  return (
    <>
      <div className={classes.root}>
        <Card className={classes.card} variant="outlined">
          {user && (
            <Link to={`${routes.customersService}/${user.id}`} onClick={onClick}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar alt={name} src="/broken-image.jpg" variant="rounded" className={classes.avatar} />
                </Grid>
                <Grid item xs container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" component="h6">
                      <strong>{name}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" component="span">
                      {user?.is_superuser ? 'Super admin' : 'Admin'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Link>
          )}

        </Card>

      </div>
      <Divider className={classes.divider} />
    </>

  );
};

export default UserProfileLink;
