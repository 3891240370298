import { Moment } from 'moment';
import { base64StringToBlob } from 'blob-util';
import flatten from 'flat';
import dateTime from 'date-time';
import { Roles } from './redux/ducks/users/users.types';
import { LotType } from './redux/ducks/lots/lots.types';

export const REQUIRED = 'required';

export const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

export const dateRegExp = RegExp(/^([0-2]\d|3[0-1])\/(0\d|1[0-2])\/[1-2]\d{3}$/);

export const passwordRegex = RegExp(
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,21}$/,
);

export const codeMaxLengthRegex = RegExp(/^[0-9]{1,9}$/);

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export const nameRegex = RegExp(
  /^((-|\s)*[a-zA-Zа-яА-Яа-ыА-Ы]){2,30}$/,
);

export const getRequestDate = (birth: string) => `${birth.split('/')[2]}-${birth.split('/')[1]}-${birth.split('/')[0]}`;

export const deepEqual = <O extends Record<string, any>>(x: O, y: O): unknown => {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length
    && ok(x).every((key) => deepEqual(x[key], y[key]))
  ) : (x === y);
};
export const getFormattedErrors = (errors:any, prefix:any) => Object.keys(errors).map((key) => ({ [`${prefix}.${key}`]: errors[key] })).reduce((a, b) => ({ ...a, ...b }));

export const addServerErrors = <T extends any>(
  errors: { [P in keyof T]?: string[] | string },
  setError: (fieldName: any, error: { type: string; message: string }) => void,
  fields?: { [P in keyof T]?: string[] | string | number | boolean } | any,
) => {
  const formatFields = fields;
  const errorsFormatted = flatten(errors, { safe: true }) as any;
  const errorsArrayFormatted = Object.keys(errorsFormatted as any);
  const fieldsArray = Object.keys(formatFields ?? {});
  const errorsNotInFields = errorsArrayFormatted.filter((f) => fieldsArray.includes(f));
  Object.keys(errorsFormatted as any).forEach((key) => {
    const isNotObject = errorsFormatted?.[key as keyof T] instanceof Array || typeof errorsFormatted[key as keyof T] === 'string';

    Object.keys(formatFields ?? {}).forEach((field) => {
      if (field === key) {
        if (isNotObject) {
          if (typeof Array(errorsFormatted[key as keyof T]) !== 'object') {
            setError(key as keyof T, {
              type: 'server',
              message: 'Please check field',
            });
          } else {
            const error = errorsFormatted[key as keyof T]
              .map((item:any) => {
                if (typeof item === 'string') {
                  return item;
                }
                return Object.entries(item)
                  .map((entry: any) => `${entry[0]} - ${entry[1]}`);
              });
            setError(key as keyof T, {
              type: 'server',
              message: typeof error?.[0] === 'string' ? error.join('. ') : error,
            });
          }
        } else {
          const fieldErrors:any = errorsFormatted[key as keyof T];
          const objectErrors = Object.values(fieldErrors).map((err:any) => err[0]).toString();
          setError(key as keyof T, {
            type: 'server',
            message: objectErrors,
          });
        }
      }
    });
  });
  const without = (object: { [P in keyof T]?: string[] | string }, keys: string[]) => Object
    .entries(object)
    .reduce((a,
      [key, value]) => (keys.indexOf(key) === -1
      ? { ...a, [key]: value }
      : a),
    {});

  const parseErrorValue = (value: unknown): string => {
    if (Array.isArray(value)) {
      return parseErrorValue(value[0]);
    }
    // eslint-disable-next-line
      if (isObject(value)) {
      return Object.values(value as Record<string, unknown>).map((key) => parseErrorValue(key)).join(", ");
    }

    return String(value);
  };

  const serverErrors = Object.fromEntries(Object
    .entries(errorsFormatted)
    .map((item) => [item[0], parseErrorValue(item[1])]));

  return without(serverErrors as {[P in keyof T]?: string[] | string}, errorsNotInFields);
};

export const withoutKey = (
  object:{
    [P in keyof Record<string, any>]?:
    string[] | string | number | null | undefined | boolean | Record<string, any>
  }, keys: string[],
) => Object
  .entries(object)
  .reduce((a,
    [key, value]) => (keys.indexOf(key) === -1
    ? { ...a, [key]: value }
    : a),
  {});

export const toCurrency = (value: number) => new Intl.NumberFormat('en-GB',
  { style: 'currency', currency: 'GBP' }).format(value);

export const toLocaleDate = (dateValue?: string | null | Date, withoutTime = false) => {
  if (!dateValue) return undefined;
  const date = new Date(dateValue);
  const timeOptions = withoutTime
    ? { }
    : {
      hour: '2-digit' as '2-digit',
      minute: '2-digit' as '2-digit',
    };

  const options = {
    year: 'numeric' as 'numeric',
    month: 'numeric' as 'numeric',
    day: 'numeric' as 'numeric',
    ...timeOptions,
  };
  return date.toLocaleString('en-GB', options);
};

export const validateDate = (date: string, message: string = 'Please, check date (dd/mm/yyyy hh:mm:ss)') => {
  const timestamp = Date.parse(date);

  if (!Number.isNaN(timestamp)) {
    return true;
  }

  return message;
};
export const validateRangeDate = (date: string[], message: string = 'Please, check date (dd/mm/yyyy)') => {
  const dateCheck = date?.map((time) => {
    const timestamp = Date.parse(time);

    return !Number.isNaN(timestamp);
  });

  const errors = dateCheck.map((err) => {
    if (!err) {
      return message;
    }
    return null;
  });

  return dateCheck.every(Boolean) ? true : errors.join('#');
};

export const getAge = (date: string) => {
  const today = new Date();
  const birthday = new Date(date);
  let thisYear = 0;
  if (today.getMonth() < birthday.getMonth()) {
    thisYear = 1;
  } else if ((today.getMonth() === birthday.getMonth()) && today.getDate() < birthday.getDate()) {
    thisYear = 1;
  }
  return today.getFullYear() - birthday.getFullYear() - thisYear;
};

export const validateImages = (val: unknown[]) => ((val.length <= 0) ? 'Please choose photo' : true);

export const numberAccept = /[\d.]/g;
export const numberAcceptNegative = /[\d.-]/g;

export const parseNumber = (string: string, allowNegative?: boolean) => (string.match(allowNegative ? numberAcceptNegative : numberAccept) || []).join('');

export const formatWithMask = (value: string | number, mask: string, allowNegative?: boolean) => {
  const digits = parseNumber(value !== null ? value.toString() : '', allowNegative);
  const chars = digits.split('');
  const getIndexes = mask.split('').reduce((acc: (string | number)[], el, i) => (el === '-' ? [...acc, i - acc.length] : acc), []);
  return chars
    .reduce(
      (acc, val, index) => (getIndexes.includes(index) ? `${acc}-${val}` : `${acc}${val}`),
      '',
    )
    .substr(0, mask.length);
};

export const formatFloatingPointNumber = (
  value: string | null,
  maxDigits: number,
  separator: boolean | undefined,
  allowNegative?: boolean,
) => {
  const parsed = parseNumber(value !== null ? value.toString() : '', allowNegative);

  const dotsAmount = parsed.split('.').length - 1;
  const numberAfterDecimalPoint = dotsAmount === 1 && parsed.split('.')[1];
  if (numberAfterDecimalPoint && numberAfterDecimalPoint === "0") return parsed;
  if (parsed[parsed.length - 1] === "." && dotsAmount === 1) return parsed;
  if (parsed === "-") return parsed;
  const number = Number.parseFloat(parsed);

  if (Number.isNaN(number)) {
    return '';
  }

  const formatted = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: 0,
    useGrouping: separator,
    maximumSignificantDigits: 21,
  }).format(number);

  if (parsed.includes('.') && maxDigits !== 0) {
    const [formattedHead, formattedDecimal] = formatted.split('.');

    if (formattedDecimal) {
      return `${formattedHead.slice(0, 14)}.${formattedDecimal.slice(0, maxDigits)}`;
    }

    return `${formattedHead.slice(0, 14)}`;
  }

  return formatted.slice(0, 14);
};

export const isTouchDevice = () => 'ontouchstart' in window;

export const capitalizeFirstLetter = (string: string) => string.charAt(0)
  .toUpperCase() + string.slice(1);

export const removeEmpty = (obj:any) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined && obj[k] !== false) && delete obj[k]);
  return obj;
};

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject | undefined => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  if (dirtyFields === false) return undefined;

  // Here, we have an object.
  return Object.fromEntries(
    // @ts-ignore
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]),
  );
};

export const updateTimer = (date:Date):string => {
  const now = new Date();
  // @ts-ignore
  const diff = date - now;
  const isPositive = diff >= 0;

  const days = Math.floor(Math.abs(diff / (1000 * 60 * 60 * 24)));
  const hours = Math.floor(Math.abs(diff / (1000 * 60 * 60)));
  const mins = Math.floor(Math.abs(diff / (1000 * 60)));
  const d = days;
  const h = hours - days * 24;
  const m = mins - hours * 60;

  return `${isPositive ? "" : "-"}${d} days, ${h} hours, ${m} minutes`;
};

export const getLocalDate = (date?: string | null | Date | Moment): Date => (date ? new Date(`${date}Z`) : new Date());

export const yearsAgo = (maxAge:any) => maxAge && new Date()
  .setFullYear(new Date().getFullYear() - maxAge);

export const formattedDate = (value:any) => (typeof value === 'string'
  ? value.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3')
  : value);

export const validateMaxAge = (value: string) => {
  const timestamp = Date.parse(formattedDate(value));
  if (getAge(formattedDate(value)) < 18) {
    return 'Minimal age is 18!';
  }
  if (!Number.isNaN(timestamp)) {
    return true;
  }
  return 'Please, check date (dd/mm/yyyy)';
};

export const adjustedDateUtc = (date:any, local = true) => {
  if (new Date(date).toString() === 'Invalid Date') {
    return new Date(date);
  }
  return dateTime({ date, local });
};

export const currencyOptions = [
  {
    value: null,
    text: 'Not selected',
  },
  {
    value: '1',
    text: 'GBP',
  },
  {
    value: '2',
    text: 'AFN',
  },
  {
    value: '4',
    text: 'AMD',
  },
  {
    value: '5',
    text: 'ANG',
  },
  {
    value: '6',
    text: 'AOA',
  },
  {
    value: '7',
    text: 'ARS',
  },
  {
    value: '8',
    text: 'AUD',
  },
  {
    value: '9',
    text: 'Euro',
  },
  {
    value: '10',
    text: 'US Dollar',
  },
];

export function readFile(file:any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // eslint-disable-next-line func-names
    reader.onload = function (event) {
      // eslint-disable-next-line no-underscore-dangle
      let _event$target;

      // eslint-disable-next-line
      resolve(event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.result);
    };

    // eslint-disable-next-line func-names
    reader.onerror = function (event) {
      reader.abort();
      reject(event);
    };

    reader.readAsDataURL(file);
  });
}

export function convertBytesToMbsOrKbs(filesize:any) {
  let size = '';
  if (filesize >= 1048576) {
    size = `${filesize / 1048576} megabytes`;
  } else if (filesize >= 1024) {
    size = `${filesize / 1024} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }

  return size;
}

export const getFileLimitExceedMessage = (filesLimit:any) => (`Maximum allowed number of files exceeded. Only ${filesLimit} allowed`);

export const getFileAddedMessage = (fileName:any) => (`${fileName}`);

export const getFileRemovedMessage = (fileName:any) => (`File ${fileName} removed.`);

export const getDropRejectMessage = (rejectedFile:any, acceptedFiles:any, maxFileSize:any) => {
  let message = `File ${rejectedFile.name} was rejected. `;
  if (!acceptedFiles.includes(rejectedFile.type)) {
    message += 'File type not supported. ';
  }
  if (rejectedFile.size > maxFileSize) {
    message += `File is too big. Size limit is ${convertBytesToMbsOrKbs(maxFileSize)}. `;
  }
  return message;
};

export const getUserRole = (isStaff?: boolean, isSuperUser?: boolean): Roles => {
  const userRole = isStaff ? 'Admin' : 'User';
  return isSuperUser ? 'Super Admin' : userRole;
};

export function isObject(value: unknown) {
  return value instanceof Object && !(value instanceof Array);
}

export const handleDownloadLink = (link: string, filename: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('href', link);
  a.setAttribute('target', "_blank");
  a.setAttribute('download', filename);
  a.click();
  document.body.removeChild(a);
};

export const handlePrintBlob = (b64: string, mime_type: string) => {
  const blob = base64StringToBlob(b64, `${mime_type}`);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl)?.print();
};

export const handleDownloadFile = ({
  b64,
  mime_type,
  filename,
  action = 'download',
}: {
  b64: string;
  mime_type: string;
  filename: string;
  action?: 'print' | 'download';
}) => {
  const link = `data:${mime_type};base64,${b64}`;
  if (action === "download") handleDownloadLink(link, filename);
  if (action === "print") handlePrintBlob(b64, mime_type);
};
export const getLotBarcode = (lot: LotType<any>) => lot.item.barcode || lot.group_items.map((item) => item.barcode).filter(Boolean).join(", ");
export const getLotSubtitle = (lot: LotType<any>) => lot.item.subtitle || lot.custom_subtitle;
export const getLotTitle = (lot: LotType<any>) => lot.item.title || lot.custom_title;
export const getLotDescription = (lot: LotType<any>) => lot.item.description || lot.custom_description;

export const parseUrlSearchParamStringToObject = (paramString: string | undefined | null) => {
  if (!paramString) return null;
  const params = new URLSearchParams(paramString);

  const result = {};
  params.forEach((value, key) => {
    if (result[key]) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return result;
};
