import { createReducer, createSelector } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { dispatchErrorMessage } from '../ui/ui';
import { IShippingState, ShippingInfoResponse } from './shipping.types';

const initialState: IShippingState = {
  pricing: {
    filename: null,
    url: null,
  },
  countriesMapping: {
    filename: null,
    url: null,
  },
  isLoading: false,
};
const createAsyncThunk = createEnhancedThunk('shipping');

export const getShippingFiles = createAsyncThunk<
  {
    pricing: IShippingState['pricing'];
    countriesMapping: IShippingState['countriesMapping'];
  },
  void
>('getShippingFiles', async (_, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await thunkAPI.extra.get<ShippingInfoResponse>(`/api/shipping_info/`);
    const pricingFilename =
      data.pricing?.split('/')[data.pricing?.split('/').length - 1];
    const countriesMappingFilename =
      data.countries_mapping?.split('/')[
        data.countries_mapping?.split('/').length - 1
      ];

    return {
      pricing: {
        filename: pricingFilename || null,
        url: data.pricing,
      },
      countriesMapping: {
        filename: countriesMappingFilename || null,
        url: data.countries_mapping,
      },
    };
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export const postShippingFiles = createAsyncThunk<
  {
    pricing: IShippingState['pricing'];
    countriesMapping: IShippingState['countriesMapping'];
  },
  {
    pricing?: File | null;
    countries_mapping?: File | null;
  }
>('postShippingFiles', async ({ pricing, countries_mapping }, thunkAPI) => {
  try {
    const formData = serialize(
      {
        pricing,
        countries_mapping,
      },
      { indices: true },
    );

    const {
      data: { data },
    } = await thunkAPI.extra.post<ShippingInfoResponse>(
      `/api/shipping_info/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const pricingFilename =
      data.pricing?.split('/')[data.pricing?.split('/').length - 1];
    const countriesMappingFilename =
      data.countries_mapping?.split('/')[
        data.countries_mapping?.split('/').length - 1
      ];

    return {
      pricing: {
        filename: pricingFilename || null,
        url: data.pricing,
      },
      countriesMapping: {
        filename: countriesMappingFilename || null,
        url: data.countries_mapping,
      },
    };
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({});
  }
});

export default createReducer(initialState, (builder) => {
  builder.addCase(getShippingFiles.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getShippingFiles.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    pricing: payload.pricing,
    countriesMapping: payload.countriesMapping,
  }));
  builder.addCase(getShippingFiles.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(postShippingFiles.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(postShippingFiles.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    pricing: payload.pricing,
    countriesMapping: payload.countriesMapping,
  }));
  builder.addCase(postShippingFiles.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));
});

export const selectShippingFiles = createSelector(
  (state: RootState) => state.shipping,
  ({ pricing, countriesMapping }) => ({ pricing, countriesMapping }),
);

export const selectShippingLoading = createSelector(
  (state: RootState) => state.shipping.isLoading,
  (isLoading) => isLoading,
);
