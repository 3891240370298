import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';

import Cookies from 'universal-cookie';
import axios from 'axios';
import {
  Forget,
  ForgetConfirm,
  ForgetConfirmPost,
  ForgetPost,
  ForgetResponse,
  ForgetResponseConfirm,
  ForgetResponseToken, ForgetToken,
  ForgetTokenPost,
} from '../../apiTypes/forgetPassword';
import { ForgetState } from './forgetPassword.types';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

const initialErrorsState = {
  forgetPasswordError: null,
  forgetEmailErrors: null,
  forgetConfirmErrors: null,
};

const initialState: ForgetState = {
  isLoading: false,
  token: '',
  ...initialErrorsState,
};

const createAsyncThunk = createEnhancedThunk('forgetPassword');

export const forgetPassword = createAsyncThunk<Forget, ForgetPost>('forgetPassword',
  async ({ email }, thunkAPI) => {
    try {
      const { data } = await axios.post<ForgetPost, ForgetResponse>(`${process.env.REACT_APP_BASE_URL}/api/auth/password_reset/`, {
        email,
      });

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const forgetPasswordValidateToken = createAsyncThunk<ForgetToken, ForgetTokenPost>('forgetPasswordValidateToken',
  async ({ token }, thunkAPI) => {
    try {
      const { data } = await axios.post<ForgetTokenPost, ForgetResponseToken >(`${process.env.REACT_APP_BASE_URL}/api/auth/password_reset/validate_token/`, {
        token,
      });
      const cookies = new Cookies();
      cookies.set('emailToken', token, { path: '/' });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const forgetPasswordConfirm = createAsyncThunk<ForgetConfirm, ForgetConfirmPost>('forgetPasswordConfirm',
  async ({ token, password }, thunkAPI) => {
    try {
      const { data } = await axios.post<ForgetConfirmPost, ForgetResponseConfirm>(`${process.env.REACT_APP_BASE_URL}/api/auth/password_reset/confirm/`, {
        token, password,
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const forgetResetErrors = createAction('RESET_ERRORS');

export default createReducer(initialState, (builder) => {
  builder.addCase(forgetPassword.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(forgetPassword.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(forgetPassword.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    forgetPasswordError: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(forgetPasswordValidateToken.fulfilled, (state, { payload }) => ({
    ...state,
    ...payload,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(forgetPasswordValidateToken.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(forgetPasswordValidateToken.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    forgetEmailErrors: action?.payload?.messages,
    isLoading: false,

  }));
  builder.addCase(forgetPasswordConfirm.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(forgetPasswordConfirm.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(forgetPasswordConfirm.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    forgetConfirmErrors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(forgetResetErrors.type, (state) => ({
    ...state,
    ...initialErrorsState,
  }));
});

export const selectForgetPasswordErrors = createSelector(
  (state: RootState) => state.forgetPassword.forgetPasswordError,
  (id) => id,
);

export const selectForgetEmailErrors = createSelector(
  (state: RootState) => state.forgetPassword.forgetEmailErrors,
  (id) => id,
);

export const selectForgetConfirmErrors = createSelector(
  (state: RootState) => state.forgetPassword.forgetConfirmErrors,
  (id) => id,
);

export const selectForgetPasswordToken = createSelector(
  (state: RootState) => state.forgetPassword.token,
  (id) => id,
);

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.forgetPassword.isLoading,
  (id) => id,
);
