import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

import {
  ActualFeesParams as ActualFeesBody,
  AuctionSettingsParams,
  EditGeneralSettingsResponse,
  EditGeneralSettingsType, GeneralPatchSettingsTypes,
  GeneralSettingsState, GeneralSettingsTypes,
  GetActualFeesType,
  GetAcutalFeesResponse,
  GetGeneralSettingsResponse,
  GetGeneralSettingsType,
} from './generalSettings.types';

const initialErrorsState = {
  errors: null,
};

const initialState: GeneralSettingsState = {
  settings: {
    group_lot_entry_fee: undefined,
    buyers_premium: undefined,
    sellers_commission: undefined,
    sellers_commission_limit: undefined,
    entry_fee: undefined,
    reserve_fee: undefined,
    vat: undefined,
    insurance_amount: undefined,
    storage_fee_percent: undefined,
    storage_fee_minimum: undefined,
    credit_card_surcharge_percent: undefined,
    jw_coordinates: undefined,
  },
  actualFees: {
    group_lot_entry_fee: undefined,
    buyers_premium: undefined,
    sellers_commission: undefined,
    sellers_commission_limit: undefined,
    entry_fee: undefined,
    reserve_fee: undefined,
    vat: undefined,
    insurance_amount: undefined,
    storage_fee_minimum: undefined,
    credit_card_surcharge_percent: undefined,
  },
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('generalSettings');

export const getGeneralSettingsAction = createAsyncThunk<GetGeneralSettingsType, AuctionSettingsParams>('getGeneralSettings',
  async (params, thunkAPI) => {
    try {
      const userId = params?.user_id;
      const auctionId = params?.auction_id;
      const response = await thunkAPI.extra.get<AuctionSettingsParams, GetGeneralSettingsResponse>('/api/auction_settings/', {
        params: {
          user_id: userId,
          auction_id: auctionId,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const editGeneralSettingsAction = createAsyncThunk<EditGeneralSettingsType, GeneralPatchSettingsTypes>('editGeneralSettings',
  async (data, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<GeneralSettingsTypes, EditGeneralSettingsResponse>('/api/auction_settings/', data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getActualFeesAction = createAsyncThunk<GetActualFeesType, ActualFeesBody>('getActualFeesAction',
  async (body, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<AuctionSettingsParams, GetAcutalFeesResponse>('/api/get_actual_fees/', body);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(editGeneralSettingsAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    settings: payload.data,
    isLoading: false,
  }));
  builder.addCase(editGeneralSettingsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editGeneralSettingsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getGeneralSettingsAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    settings: payload.data,
    isLoading: false,
  }));
  builder.addCase(getGeneralSettingsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getGeneralSettingsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getActualFeesAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    actualFees: payload.data,
    isLoading: false,
  }));
  builder.addCase(getActualFeesAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getActualFeesAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.generalSettings.isLoading,
  (isLoading) => isLoading,
);

export const selectGeneralSettings = createSelector(
  (state: RootState) => state.generalSettings.settings,
  (settings) => settings,
);

export const selectActualFees = createSelector(
  (state: RootState) => state.generalSettings.actualFees,
  (actualFees) => actualFees,
);

export const selectErrors = createSelector(
  (state: RootState) => state.generalSettings.errors,
  (errors) => errors,
);
