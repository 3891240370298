import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

import {
  CurrenciesState, GetCurrenciesResponse, GetCurrenciesType,
} from './currencies.types';

const initialErrorsState = {
  errors: null,
};

const initialState: CurrenciesState = {
  result: [],
  isLoading: false,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('currencies');

export const getCurrencies = createAsyncThunk<GetCurrenciesType, void>('getEmailTemplates',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<void, GetCurrenciesResponse>('/api/currencies/');
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getCurrencies.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data,
    isLoading: false,
  }));
  builder.addCase(getCurrencies.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getCurrencies.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.currencies.isLoading,
  (isLoading) => isLoading,
);

export const selectCurrencies = createSelector(
  (state: RootState) => state.currencies.result,
  (result) => result,
);
export const selectCurrenciesFormatted = createSelector(
  (state: RootState) => state.currencies.result
    ?.map((item) => ({ value: item[0], text: item[0] })),
  (result) => result,
);

export const selectCurrenciesErrors = createSelector(
  (state: RootState) => state.currencies.errors,
  (errors) => errors,
);
