import React, {
  FC, lazy, Suspense, useEffect, useState,
} from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from './routes';
import SignIn from './pages/SignIn';
import ResetPassword from './pages/ResetPassword';
import { ProtectedRoute } from './components/atoms/ProtectedRoute';
import { Layout } from './components/organisms/Layout';
import { PageLoader } from './components/atoms/PageLoader';
import { ScrollToTop } from './components/atoms/ScrollToTop';
import { RouteLeavingGuard } from './components/atoms/RouteLeavingGuard';
import { selectIsBackUnAvailable } from './redux/ducks/ui/ui';
import { selectCurrentUser } from './redux/ducks/users/users';

const Home = lazy(() => import('./pages/Home'));
const Shipping = lazy(() => import('./pages/Shipping'));
const OrderStorages = lazy(() => import('./pages/OrderStorages'));
const OrderStorageWarehouse = lazy(() => import('./pages/OrderStorageWarehouse'));
const OrderStatuses = lazy(() => import('./pages/OrderStatuses'));
const OrderStatus = lazy(() => import('./pages/OrderStatus'));
const Orders = lazy(() => import('./pages/Orders'));
const Order = lazy(() => import('./pages/Order'));
const Lots = lazy(() => import('./pages/Lots'));
const Lot = lazy(() => import('./pages/Lot'));
const Templates = lazy(() => import('./pages/Templates'));
const Template = lazy(() => import('./pages/Template'));
const Auctions = lazy(() => import('./pages/Auctions'));
const Auction = lazy(() => import('./pages/Auction'));
const Voucher = lazy(() => import('./pages/Voucher'));
const Vouchers = lazy(() => import('./pages/Vouchers'));
const SellerSheets = lazy(() => import('./pages/SellerSheets'));
const SellerSheet = lazy(() => import('./pages/SellerSheet'));
const Customers = lazy(() => import('./pages/Customers'));
const Customer = lazy(() => import('./pages/Customer'));
const GeneralSettings = lazy(() => import('./pages/GeneralSettings'));
const AttributesAndValues = lazy(() => import('./pages/AttributesAndValues'));
const WalletTransactions = lazy(() => import('./pages/WalletTransactions'));
const WalletCustomers = lazy(() => import('./pages/WalletCustomers'));
const SellRequests = lazy(() => import('./pages/SellRequests'));
const CMSHomepage = lazy(() => import('./pages/CMS/CMSHomepage'));

const Page404 = lazy(() => import('./components/templates/Page404'));

const REDIRECT_URL_CLEAN_TIME = 10000;

const Router: FC = () => {
  const history = useHistory();
  const IsBackUnAvailable = useSelector(selectIsBackUnAvailable);
  const user = useSelector(selectCurrentUser);
  const isSuperUser = user?.is_superuser;
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect');
      if (redirectUrl) {
        history.replace('/');
        setRedirectUrl(redirectUrl);
      }
      return;
    }
    if (redirectUrl && isSuperUser) {
      history.push(redirectUrl);
      setRedirectUrl(undefined);
    }
  }, [location, isSuperUser]);

  useEffect(() => {
    const timer = setTimeout(() => setRedirectUrl(undefined), REDIRECT_URL_CLEAN_TIME);

    return () => clearTimeout(timer);
  }, [redirectUrl]);

  const onBlockNavigation = (location: { pathname: string }) => IsBackUnAvailable
    || (IsBackUnAvailable && location?.pathname === routes.login);

  return (
    <Switch>
      <ProtectedRoute
        title="Reset Password"
        exact
        component={ResetPassword}
        path={routes.forgotPassword}
        isPrivate={false}
      />

      <ProtectedRoute
        title="Login"
        exact
        component={SignIn}
        path={routes.login}
        isPrivate={false}
      />

      <Layout>
        <RouteLeavingGuard
          when={IsBackUnAvailable}
          navigate={(path: string) => {
            history.push(path);
          }}
          shouldBlockNavigation={onBlockNavigation}
        />
        <ScrollToTop />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <ProtectedRoute title="Home" exact component={Home} path={routes.home} />
            <ProtectedRoute title="Lots" exact component={Lots} path={routes.lots} />
            <ProtectedRoute title="Lot" exact component={Lot} path={routes.lot} />
            <ProtectedRoute title="Templates" exact component={Templates} path={routes.templates} />
            <ProtectedRoute title="Template" exact component={Template} path={routes.template} />
            <ProtectedRoute title="Auctions" exact component={Auctions} path={routes.auctions} />
            <ProtectedRoute title="Auction" exact component={Auction} path={routes.auction} />
            <ProtectedRoute title="Vouchers" exact component={Vouchers} path={routes.vouchers} />
            <ProtectedRoute title="Voucher" exact component={Voucher} path={routes.voucher} SuperUserRoute />
            <ProtectedRoute title="Seller Sheets" exact component={SellerSheets} path={routes.sellerSheets} />
            <ProtectedRoute title="Seller Sheet" exact component={SellerSheet} path={routes.sellerSheet} />
            <ProtectedRoute title="Customers" exact component={Customers} path={routes.customersService} />
            <ProtectedRoute title="Customer" exact component={Customer} path={routes.customerService} />
            <ProtectedRoute title="Orders" exact component={Orders} path={routes.orders} />
            <ProtectedRoute title="Order" exact component={Order} path={routes.order} />
            <ProtectedRoute title="Orders Statuses" exact component={OrderStatuses} path={routes.order_statuses} />
            <ProtectedRoute title="Orders Status" exact component={OrderStatus} path={routes.order_status} />
            <ProtectedRoute title="Orders Storages" exact component={OrderStorages} path={routes.order_storages} />
            <ProtectedRoute title="Orders Warehouse" exact component={OrderStorageWarehouse} path={routes.order_storage} />
            <ProtectedRoute title="Shipping management" exact component={Shipping} path={routes.shipping} SuperUserRoute />
            <ProtectedRoute title="General Settings" exact component={GeneralSettings} path={routes.generalSettings} SuperUserRoute />
            <ProtectedRoute title="Wallet Transactions" exact component={WalletTransactions} path={routes.wallet_transactions} />
            <ProtectedRoute title="Wallet Customers" exact component={WalletCustomers} path={routes.wallet_customers} />
            <ProtectedRoute title="Sell Requests" exact component={SellRequests} path={routes.sell_requests} />
            <ProtectedRoute
              title="Attributes and Values"
              exact
              component={AttributesAndValues}
              path={routes.attributesAndValues}
            />

            <ProtectedRoute title="Homepage Content" exact component={CMSHomepage} path={routes.cms_homepage} />

            <ProtectedRoute title="Not found" component={Page404} />
          </Switch>
        </Suspense>
      </Layout>
    </Switch>
  );
};

export default Router;
