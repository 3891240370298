import moment from "moment";
import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import createEnhancedThunk from "../../enhancedAsyncThunkCreator";
import { RootState } from "../../rootReducer";
import { tablePaginationParams } from "../../../staticData";
import { PaginationType } from "../../apiTypes/shared/pagintaion";
import { dispatchErrorMessage } from "../ui/ui";
import {
  IVoucher,
  IVoucherParams,
  VoucherResponse,
  VouchersListResponse,
  VouchersState,
} from "./vouchers.types";
// import mock from "./mock.json";
// import mockId from "./mockId.json";

const today = new Date();
const newYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

const initialState: VouchersState = {
  results: [],
  errors: {},
  voucher: {
    order: null,
    description: "",
    code: "",
    valid_from: today.toISOString(),
    valid_to: newYear.toISOString(),
    discount: "",
    is_active: true,
    id: 0,
  },
  isLoading: false,
  ...tablePaginationParams,
};
const createAsyncThunk = createEnhancedThunk("vouchers");

export const getVouchersList = createAsyncThunk<
  VouchersListResponse,
  PaginationType & IVoucherParams
>("getVouchersList", async ({ page, perPage, params }, thunkAPI) => {
  try {
    const response = await thunkAPI.extra.get<VouchersListResponse>(
      "/api/admin_voucher/",
      {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      },
    );
    return response.data;
    // return mock;
  } catch (err) {
    thunkAPI.dispatch(dispatchErrorMessage(err));
    return thunkAPI.rejectWithValue({
      messages: err?.response?.data?.data ?? { error: ["Server error"] },
    });
  }
});

export const getVoucher = createAsyncThunk<IVoucher, number>(
  "getVoucher",
  async (id, thunkAPI) => {
    try {
      const result = await thunkAPI.extra.get<VoucherResponse>(`/api/admin_voucher/${id}/`);
      return result.data.data;
      // return mockId.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ["Server error"] },
      });
    }
  },
);

export const deleteVoucher = createAsyncThunk<void, number>(
  "deleteVoucher",
  async (id, thunkAPI) => {
    try {
      await thunkAPI.extra.delete<void>(`/api/admin_voucher/${id}/`);
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ["Server error"] },
      });
    }
  },
);

export const postVoucher = createAsyncThunk<IVoucher, IVoucher>(
  "postVoucher",
  async ({
    code,
    discount,
    validity,
    is_active,
    description,
  }, thunkAPI) => {
    const [valid_from, valid_to] = validity || [];
    try {
      const result = await thunkAPI.extra.post<VoucherResponse>(`/api/admin_voucher/`, {
        code,
        discount,
        is_active,
        description,
        valid_from: moment(valid_from)?.toISOString(),
        valid_to: moment(valid_to)?.toISOString(),
      });
      return result.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ["Server error"] },
      });
    }
  },
);

export const patchVoucher = createAsyncThunk<IVoucher, Partial<IVoucher>>(
  "patchVoucher",
  async ({
    code,
    discount,
    validity,
    is_active,
    description,
    id,
  }, thunkAPI) => {
    const [valid_from, valid_to] = validity || [];

    try {
      const result = await thunkAPI.extra.patch<VoucherResponse>(`/api/admin_voucher/${id}/`, {
        code,
        discount,
        is_active,
        description,
        valid_from: valid_from && moment(valid_from)?.toISOString(),
        valid_to: valid_to && moment(valid_to)?.toISOString(),
      });
      return result.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ["Server error"] },
      });
    }
  },
);

export const resetVoucher = createAction("resetVoucher");

export default createReducer(initialState, (builder) => {
  builder.addCase(getVouchersList.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getVouchersList.fulfilled, (state, { payload, meta }) => ({
    ...state,
    results: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getVouchersList.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(deleteVoucher.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteVoucher.fulfilled, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(deleteVoucher.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getVoucher.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getVoucher.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    voucher: payload,
  }));
  builder.addCase(getVoucher.rejected, (state) => ({
    ...state,
    isLoading: false,
    voucher: {
      ...initialState.voucher,
    },
  }));

  builder.addCase(resetVoucher, (state) => ({
    ...state,
    voucher: {
      ...initialState.voucher,
    },
  }));

  builder.addCase(postVoucher.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(postVoucher.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    voucher: {
      ...payload,
    },
    errors: {},
  }));
  builder.addCase(postVoucher.rejected, (state, action) => ({
    ...state,
    isLoading: false,
    // @ts-ignore
    errors: action?.payload?.messages,
  }));

  builder.addCase(patchVoucher.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(patchVoucher.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    voucher: {
      ...payload,
    },
    errors: {},
  }));
  builder.addCase(patchVoucher.rejected, (state, action) => ({
    ...state,
    isLoading: false,
    // @ts-ignore
    errors: action?.payload?.messages,
  }));
});

export const selectVouchersList = createSelector(
  (state: RootState) => state.vouchers.results,
  (result) => result,
);

export const selectVouchersPagination = createSelector(
  (state: RootState) => state.vouchers,
  ({ perPage, count, page }) => ({ perPage, count, page }),
);

export const selectVoucher = createSelector(
  (state: RootState) => state.vouchers.voucher,
  (voucher) => voucher,
);

export const selectVoucherLoading = createSelector(
  (state: RootState) => state.vouchers.isLoading,
  (isLoading) => isLoading,
);

export const selectVoucherErrors = createSelector(
  (state: RootState) => state.vouchers.errors,
  (errors) => errors,
);
