import { createReducer, createSelector } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';

import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  DistilleryState, DistilleryType, EditDistilleryResponse, EditDistilleryType,
  GetDistilleryResponse,
  GetDistillerysResponse,
  GetDistillerysType,
  GetDistilleryType, PatchDistilleryType,
  PostDistilleryResponse,
  PostDistilleryType,
} from './itemDistillery.types';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: DistilleryState = {
  result: [],
  distillery: undefined,
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('itemDistillery');

export const getDistillerysAction = createAsyncThunk<GetDistillerysType, PaginationType & any>('getDistillerys',
  async ({ page, perPage, params }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetDistillerysResponse>('/api/item_distillery/', {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getDistilleryAction = createAsyncThunk<GetDistilleryType, { id: number }>('getDistillery',
  async ({ id }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<{ id: number }, GetDistilleryResponse>(`/api/item_distillery/${id}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const createDistilleryAction = createAsyncThunk<PostDistilleryType, { data: DistilleryType }>('createDistilleryAction',
  async ({ data }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.post<DistilleryType, PostDistilleryResponse>('/api/item_distillery/', data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const editDistilleryAction = createAsyncThunk<EditDistilleryType, { id: number, data: PatchDistilleryType }>('editDistilleryAction',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.patch<DistilleryType, EditDistilleryResponse>(`/api/item_distillery/${id}/`, data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });
export const deleteDistilleryAction = createAsyncThunk<null, { id?: number }>('deleteDistillery',
  async ({ id }, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/item_distillery/${id}/`);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const bulkDeleteDistilleryAction = createAsyncThunk<null, number[]>('bulkDeleteDistilleryAction',
  async (ids, thunkAPI) => {
    try {
      return await thunkAPI.extra.delete(`/api/item_distillery/`, {
        headers: {
          'X-BULK-OPERATION': 'true',
        },
        params: {
          id: ids,
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
      });
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(createDistilleryAction.fulfilled, (state, action) => {
    const distillerys = state.result.map((distillery) => {
      if (distillery.id === action.payload.data.id) {
        return action.payload.data;
      }
      return distillery;
    });

    return ({
      ...state,
      ...initialErrorsState,
      distillery: action.payload.data,
      result: [...distillerys, action.payload.data],
      count: state.count + 1,
      isLoading: false,
    });
  });
  builder.addCase(createDistilleryAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(createDistilleryAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(editDistilleryAction.fulfilled, (state, action) => {
    const distillerys = state.result.map((distillery) => {
      if (distillery.id === action.payload.data.id) {
        return action.payload.data;
      }
      return distillery;
    });

    return ({
      ...state,
      ...initialErrorsState,
      distillery: action.payload.data,
      result: distillerys,
      isLoading: false,
    });
  });
  builder.addCase(editDistilleryAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(editDistilleryAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(getDistillerysAction.fulfilled, (state, {
    payload,
    meta,
  }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getDistillerysAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getDistillerysAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(getDistilleryAction.fulfilled, (state, { payload }) => ({
    ...state,
    ...initialErrorsState,
    distillery: payload.data,
    isLoading: false,
  }));
  builder.addCase(getDistilleryAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getDistilleryAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));

  builder.addCase(deleteDistilleryAction.fulfilled, (state) => ({
    ...state,
    ...initialErrorsState,
    isLoading: false,
  }));
  builder.addCase(deleteDistilleryAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(deleteDistilleryAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteDistilleryAction.rejected, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(bulkDeleteDistilleryAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(bulkDeleteDistilleryAction.fulfilled, (state) => ({
    ...state,
    isLoading: false,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.itemDistillery.isLoading,
  (id) => id,
);

export const selectDistillerys = createSelector(
  (state: RootState) => state.itemDistillery.result,
  (id) => id,
);

export const selectDistillery = createSelector(
  (state: RootState) => state.itemDistillery.distillery,
  (id) => id,
);

export const selectDistillerysCount = createSelector(
  (state: RootState) => state.itemDistillery.count,
  (id) => id,
);
export const selectDistillerysPage = createSelector(
  (state: RootState) => state.itemDistillery.page,
  (id) => id,
);
export const selectDistillerysPerPage = createSelector(
  (state: RootState) => state.itemDistillery.perPage,
  (id) => id,
);

export const selectErrors = createSelector(
  (state: RootState) => state.itemDistillery.errors,
  (id) => id,
);
