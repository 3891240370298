import { combineReducers } from '@reduxjs/toolkit';
import ui from './ducks/ui/ui';
import auth from './ducks/auth/auth';
import forgetPassword from './ducks/forgetPassword/forgetPassword';
import lots from './ducks/lots/lots';
import auctions from './ducks/auctions/auctions';
import asyncSelects from './ducks/asyncSelects/asyncSelects';
import templates from './ducks/templates/templates';
import bids from './ducks/bids/bids';
import users from './ducks/users/users';
import sellerSheet from './ducks/sellerSheet/sellerSheet';
import itemBottler from './ducks/itemBottler/itemBottler';
import itemDistillery from './ducks/itemDistillery/itemDistillery';
import generalSettings from './ducks/generalSettings/generalSettings';
import usersSellerSheets from './ducks/usersSellerSheets/usersSellerSheets';
import sellerSheetMiscellaneousCharges from './ducks/sellerSheetMiscellaneousCharges/sellerSheetMiscellaneousCharges';
import orderStatuses from './ducks/orderStatuses/orderStatuses';
import emailTemplates from './ducks/emailTemplates/emailTemplates';
import images from './ducks/images/images';
import currencies from './ducks/currencies/currencies';
import paymentMethods from './ducks/paymentMethods/paymentMethods';
import orders from './ducks/orders/orders';
import addresses from './ducks/addresses/addresses';
import countries from './ducks/countries/countries';
import storage from './ducks/orderStorage/orderStorage';
import wallet from './ducks/wallet/wallet';
import sellRequests from './ducks/sellRequests/sellRequests';
import sellerSheetPaymentRecords from './ducks/sellerSheetPaymentRecords/sellerSheetPaymentRecords';
import vouchers from './ducks/vouchers/vouchers';
import shipping from './ducks/shipping/shipping';
import CMS from './ducks/CMS/CMS';

const rootReducer = combineReducers({
  auth,
  forgetPassword,
  ui,
  lots,
  auctions,
  templates,
  asyncSelects,
  bids,
  users,
  sellerSheet,
  itemBottler,
  itemDistillery,
  generalSettings,
  images,
  usersSellerSheets,
  sellerSheetMiscellaneousCharges,
  orderStatuses,
  emailTemplates,
  currencies,
  paymentMethods,
  orders,
  addresses,
  countries,
  storage,
  wallet,
  sellRequests,
  sellerSheetPaymentRecords,
  vouchers,
  shipping,
  CMS,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
