import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';

import { PaginationType } from '../../apiTypes/shared/pagintaion';
import { EmailTemplatesState, GetEmailTemplatesResponse, GetEmailTemplatesType } from './emailTemplates.types';

const initialErrorsState = {
  errors: null,
};

const initialState: EmailTemplatesState = {
  result: [],
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('emailTemplates');

export const getEmailTemplates = createAsyncThunk<GetEmailTemplatesType, PaginationType>('getEmailTemplates',
  async ({ page, perPage }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<PaginationType, GetEmailTemplatesResponse>('/api/email_templates/', {
        params: {
          page: page + 1,
          page_size: perPage,
        },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getEmailTemplates.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getEmailTemplates.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getEmailTemplates.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.emailTemplates.isLoading,
  (id) => id,
);

export const selectEmailTemplates = createSelector(
  (state: RootState) => state.emailTemplates.result,
  (result) => result,
);
export const selectEmailTemplatesFormatted = createSelector(
  (state: RootState) => state.emailTemplates.result
    .map(({ id, name, link }) => ({ value: id, text: name, link })),
  (result) => result,
);

export const selectEmailTemplatesCount = createSelector(
  (state: RootState) => state.emailTemplates.count,
  (count) => count,
);

export const selectEmailTemplatesPage = createSelector(
  (state: RootState) => state.emailTemplates.page,
  (page) => page,
);

export const selectEmailTemplatesPerPage = createSelector(
  (state: RootState) => state.emailTemplates.perPage,
  (page) => page,
);

export const selectEmailTemplatesErrors = createSelector(
  (state: RootState) => state.emailTemplates.errors,
  (page) => page,
);
