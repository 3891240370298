import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentModal, setCurrentModal } from '../../../redux/ducks/ui/ui';
import { ConfirmModal } from '../ConfirmModal';
import { useAppDispatch } from '../../../redux/store';
import { SuccessfulModal } from '../SuccessfulModal';

const ModalsWrap = () => {
  const currentModal = useSelector(selectCurrentModal);
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(setCurrentModal({
      title: '',
      modal: 'closed',
    }));
  };
  return (
    <>
      <ConfirmModal
        isOpen={currentModal?.modal === 'confirmModal'}
        onClose={onClose}
      />
      <SuccessfulModal
        isOpen={currentModal?.modal === 'successfulModal'}
        onClose={onClose}
      />
    </>
  );
};

export default ModalsWrap;
