import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageLoader: {
    height: window.innerHeight, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
  },
}));

const PageLoader:FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageLoader}>
      <CircularProgress color="secondary" />
    </div>
  );
};

export default PageLoader;
