import React, { FC } from 'react';
import {
  IconButton, List, ListItem, ListItemIcon, ListItemText, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WebIcon from '@mui/icons-material/Web';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import cn from 'classnames';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useSelector } from 'react-redux';
import { Props } from './NavList.types';
import { CollapseList } from '../CollapseList';
import { UserProfileLink } from '../UserProfileLink';
import routes from '../../../routes';
import { selectCurrentUser } from '../../../redux/ducks/users/users';

const listItems = [
  {
    name: 'Dashboard',
    link: routes.home,
    links: [],
    icon: <DashboardIcon />,
    onlySuperUser: false,
  },
  {
    name: 'DMS',
    link: '/',
    icon: <WebIcon />,
    onlySuperUser: false,
    links: [{
      name: 'Seller sheets',
      link: routes.sellerSheets,
      onlySuperUser: false,
    }, {
      name: 'Lots',
      link: routes.lots,
    }, {
      name: 'Customers',
      link: routes.customersService,
      onlySuperUser: false,
    }, {
      name: 'Templates',
      link: routes.templates,
      onlySuperUser: false,
    },
    {
      name: 'Attributes and Values',
      link: routes.attributesAndValues,
      onlySuperUser: false,
    },
    ],
  },
  {
    name: 'Orders',
    link: '/',
    onlySuperUser: false,
    links: [{
      name: 'Orders',
      link: routes.orders,
      onlySuperUser: false,
    }, {
      name: 'Order Storage',
      link: routes.order_storages,
      onlySuperUser: false,

    }, {
      name: 'Order Statuses',
      link: routes.order_statuses,
      onlySuperUser: false,
    },
    ],
    icon: <BusinessCenterIcon />,
  },
  {
    name: 'Auction settings',
    link: routes.auctions,
    onlySuperUser: false,
    links: [],
    icon: <AccessTimeIcon />,
  },
  {
    name: 'Wallets',
    link: '/',
    icon: <AccountBalanceWalletIcon />,
    onlySuperUser: false,
    links: [{
      name: 'Customers',
      link: routes.wallet_customers,
      onlySuperUser: false,
    }, {
      name: 'Transactions',
      link: routes.wallet_transactions,
      onlySuperUser: false,
    },
    ],
  },
  {
    name: 'Global Auction Settings',
    link: routes.generalSettings,
    links: [],
    icon: <SettingsIcon />,
    onlySuperUser: true,
  },
  {
    name: 'Voucher Management',
    link: routes.vouchers,
    onlySuperUser: false,
    links: [],
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: 'Shipping Management',
    link: '/shipping',
    onlySuperUser: true,
    links: [],
    icon: <LocalShippingIcon />,
  },
  {
    name: 'Accountancy',
    link: '/accountancy',
    onlySuperUser: false,
    links: [],
    icon: <PersonIcon />,
  },
  {
    name: 'Content management',
    link: '/',
    onlySuperUser: true,
    links: [
      {
        name: 'Homepage',
        link: routes.cms_homepage,
        onlySuperUser: true,
      },
    ],
    icon: <DescriptionIcon />,
  },
  {
    name: 'Sell Requests',
    link: routes.sell_requests,
    onlySuperUser: false,
    links: [],
    icon: <DescriptionIcon />,
  },
];

const useStyles = makeStyles((theme:Theme) => ({
  list: {
    width: 350,
    maxWidth: '100vw',
  },
  link: {
    width: 350,
    maxWidth: '100%',
  },
  linkActive: {
    backgroundColor: theme.palette.action.selected,
  },
  listInner: {
    position: 'relative',
    overflow: 'auto',
    height: 'calc(100vh - 236px)',
    paddingTop: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    zIndex: 111,
    right: 10,
    top: 5,
  },
}));

const NavList: FC<Props> = ({ onLink }) => {
  const classes = useStyles();
  const history = useLocation();
  const activeRoute = history.pathname.split('/')[1];
  const user = useSelector(selectCurrentUser);
  const isSuperUser = user?.is_superuser;
  const toggleDrawer = (state: boolean) => () => {
    onLink(state)();
  };

  return (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <UserProfileLink onClick={toggleDrawer(false)} />
      <IconButton
        className={classes.closeButton}
        color={'info' as 'inherit'}
        size="small"
        onClick={toggleDrawer(false)}
      >
        <CloseIcon />
      </IconButton>
      <List className={classes.listInner}>
        {listItems.map(({
          name,
          link,
          icon,
          links,
          onlySuperUser,
        }) => (
          <div key={name}>
            {links.length <= 0 ? (
              <>
                { (!onlySuperUser
                  || onlySuperUser === isSuperUser) ? (
                    <ListItem
                      button
                      component={NavLink}
                      to={link}
                      className={cn(classes.link, { [classes.linkActive]: link === `/${activeRoute}` })}
                      onClick={toggleDrawer(false)}
                      exact
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItem>
                  ) : null}
              </>
            ) : (
              <>
                { (!onlySuperUser
                  || onlySuperUser === isSuperUser) && (
                  <CollapseList
                    title={name}
                    links={links}
                    onLink={toggleDrawer}
                    icon={icon}
                    activeRoute={activeRoute}
                  />
                )}
              </>
            )}
          </div>
        ))}
      </List>
    </div>
  );
};

export default NavList;
