import { passwordRegex, validateImages, validEmailRegex } from './utilities';

export const colorErrorMessage = 'Status will be highlighted  in this color. (HTML colors only) "lightblue", "#CC6600")';

export const passwordRules = {
  required: 'Please, specify your password',
  minLength: {
    value: 8,
    message: 'Min length is 8',
  },
  pattern: {
    value: passwordRegex,
    message:
      'Password must contain one upper case letter, one lower case letter, one digit, one special symbol, 8-20 length, and no spaces.',
  },
};

export const textAreaRules = {
  maxLength: {
    value: 300,
    message: 'Please reduce text length to 300 characters',
  },
};

export const requiredRulesInput = {
  required: 'Please, specify your input',
};
export const requiredRulesImages = {
  required: 'Please, specify your input',
  validate: validateImages,
};

export const emailRules = {
  required: 'Please, specify your email',
  pattern: {
    value: validEmailRegex,
    message: 'Invalid email address',
  },
};

export const barcodeRules = {
  minLength: {
    value: 4,
    message: 'Please increase barcode length to 4 characters',
  },
  maxLength: {
    value: 13,
    message: 'Please reduce barcode length to 13 characters',
  },
};

export const itemTitleRules = {
  required: 'Please enter a valid title',
  maxLength: {
    value: 40,
    message: 'Please reduce title length to 40 characters',
  },
};

export const itemSubTitleRules = {
  required: 'Please enter a valid subtitle',
  maxLength: {
    value: 40,
    message: 'Please reduce subtitle length to 40 characters',
  },
};

export const itemDescriptionRules = {
  required: 'Please enter a valid description',
  maxLength: {
    value: 3000,
    message: 'Please reduce subtitle length to 3000 characters',
  },
};

export const itemStrengthRules = (required?: boolean) => ({
  required: required ? 'Please enter valid alcohol strength' : undefined,
});

export const itemBottleSizeRules = (required?: boolean) => ({
  required: required ? 'Please enter valid bottle size' : undefined,
});

export const itemDistilleryRules = (required?: boolean) => ({
  required: required ? 'Please correct distillery' : undefined,
  minLength: {
    value: 4,
    message: 'Please increase Distillery length to 4 characters',
  },
  maxLength: {
    value: 40,
    message: 'Please reduce Distillery length to 40 characters',
  },
});

export const itemBottlerRules = (required?: boolean) => ({
  required: required ? 'Please correct bottler ' : undefined,
  minLength: {
    value: 4,
    message: 'Please increase Bottler length to 4 characters',
  },
  maxLength: {
    value: 40,
    message: 'Please reduce Bottler length to 40 characters',
  },
});

export const itemBottlerNumberRules = {
  minLength: {
    value: 1,
    message: 'Please check bottles produced (too low?), min length 1',
  },
  maxLength: {
    value: 6,
    message: 'Please check bottles produced (too high?), max length 6',
  },
};

export const itemCaskNumberRules = {
  minLength: {
    value: 1,
    message: 'Please check cask number produced (too low?), min length 1',
  },
  maxLength: {
    value: 100,
    message: 'Please check cask number produced (too high?), max length 100',
  },
};

export const itemCaskTypeRules = {
  minLength: {
    value: 4,
    message: 'Please increase Cask Type length to 4 characters',
  },
  maxLength: {
    value: 40,
    message: 'Please reduce Cask Type length to 40 characters',
  },
};

export const itemCaskFinishRules = {
  minLength: {
    value: 4,
    message: 'Please increase Cask Finish length to 4 characters',
  },
  maxLength: {
    value: 40,
    message: 'Please reduce Cask Finish length to 40 characters',
  },
};

export const itemAppoxShippingRules = {
  required: 'Please check shipping weight ',
  min: {
    value: 0.1,
    message: 'Please check shipping weight and enter valid weight, not lower than 0.1',
  },
  max: {
    value: 100,
    message: 'Please check shipping weight and enter valid weight, not bigger than 100.0',
  },
};

export const pleaseNoteRules = {
  maxLength: {
    value: 1000,
    message: 'Please reduce "Please Note" length to 1000 characters',
  },
};

export const warehouseLocationRules = {
  required: 'This field is required!',

  minLength: {
    value: 2,
    message: 'Please check length of Warehouse Location field and increase to 2 characters',
  },
  maxLength: {
    value: 8,
    message: 'Please check length of Warehouse Location field and reduce to 8 characters',
  },
};

export const reservePriceRules = {
  minLength: {
    value: 1,
    message: 'Please enter a valid Reserve Price, not lower than 1 character',
  },
  maxLength: {
    value: 12,
    message: 'Please enter a valid Reserve Price, not bigger than 12 characters',
  },
};

export const startingPriceRules = {
  minLength: {
    value: 1,
    message: 'Please enter a valid Starting Price, not lower than 1 character',
  },
  maxLength: {
    value: 12,
    message: 'Please enter a valid Starting Price, not bigger than 12 characters',
  },
};

export const entryFeeRules = {
  required: 'Please enter a valid Entry Fee ',
  min: {
    value: 0,
    message: 'Please enter a valid Entry Fee Charge not lower, than 1',
  },
  max: {
    value: 999.99,
    message: 'Please enter a valid Entry Fee Charge, not bigger than 999.99',
  },
};

export const entryFeeLimitRules = {
  min: {
    value: 1,
    message: 'Please enter a valid Entry Fee Limit, not lower than 1',
  },
  max: {
    value: 999.99,
    message: 'Please enter a valid Entry Fee Limit, not bigger than 999.99',
  },
};

export const sellerSheetNotesRules = {
  maxLength: {
    value: 1000,
    message: 'Please reduce "notes" length to 1000 characters',
  },
};

export const sellerDiscountAmountRules = {
  minLength: {
    value: 1,
    message: 'Please enter a valid Seller Discount Amount in GBP, not lower than 1 characters',
  },
  maxLength: {
    value: 8,
    message: 'Please enter a valid Seller Discount Amount in GBP, not bigger than 8 characters',
  },
};

export const sellerDiscountDescriptionRules = {
  required: 'Please check seller discount description ',
  maxLength: {
    value: 1000,
    message: 'Please reduce length of seller discount description, not bigger than 1000 characters',
  },
};

export const miscellaneousChargeAmountRules = {
  minLength: {
    value: 1,
    message: 'Please enter a valid Misc. charges amount in GBP, not lower than 1 character',
  },
  maxLength: {
    value: 8,
    message: 'Please enter a valid Misc. charges amount in GBP, not bigger than 8 characters',
  },
};

export const vatRateRules = {
  required: 'Please enter a valid VAT rate',
  min: {
    value: 0,
    message: 'Please enter a valid VAT rate in % (example "20"), not lower than 0',
  },
  max: {
    value: 99.99,
    message: 'Please enter a valid VAT rate in % (example "20"), not bigger than 99.99',
  },
};

export const sellerCommissionRules = {
  required: 'Please enter a valid seller commission rate ',
  min: {
    value: 0,
    message: 'Please enter a valid seller commission rate in %, not lower than 0',
  },
  max: {
    value: 99.99,
    message: 'Please enter a valid seller commission rate in %, not bigger than 99.99',
  },
};

export const commissionLimitRules = {
  required: 'Please enter a valid commission limit amount',
  min: {
    value: 100,
    message: 'Please enter a valid commission limit amount, not lower than 100',
  },
  max: {
    value: 999999.99,
    message: 'Please enter a valid commission limit amount, not bigger than 999999.99',
  },
};

export const auctionNumberRules = {
  required: 'Please enter a valid auction number (example "101")',
  pattern: {
    value: RegExp(/^\d+$/),
    message: 'Only numeric symbols are allowed',
  },
  minLength: {
    value: 3,
    message: 'Please enter a valid auction number (example "101")',
  },
  maxLength: {
    value: 10,
    message: 'Please enter a valid auction number (example "101")',
  },
};

export const auctionNameRules = {
  required: 'Please enter a valid auction name',
  maxLength: {
    value: 40,
    message: 'Please check auction name (too long), not bigger than 40 characters',
  },
};

export const auctionDescriptionRules = {
  required: 'Please check auction description length ',
  maxLength: {
    value: 1000,
    message: 'Please check auction description length (too long), not bigger than 1000 characters',
  },
};

export const voucherAmountRules = {
  required: 'Please enter a valid amount',
  min: {
    value: 1,
    message: 'Minimum amount it 1GBP',
  },
  max: {
    value: 10000,
    message: 'Maximum amount it 10000GBP',
  },
};
export const voucherCodeRules = (required?: boolean) => ({
  required: required ? "Can't leave this field empty" : undefined,
  minLength: {
    value: 3,
    message: 'Please enter a Code, not lower than 3',
  },
  maxLength: {
    value: 10,
    message: 'Please check Code (too long), not bigger than 10 characters',
  },
});

export const referenceNumberRules = {
  ...requiredRulesInput,
  pattern: {
    value: RegExp(/^\d+$/),
    message: 'Only numeric symbols are allowed',
  },
  minLength: {
    value: 7,
    message: 'Min length is 7',
  },
  maxLength: {
    value: 20,
    message: 'Max length is 20',
  },
};

export const sortCodeRules = {
  required: 'Please, specify Sort Code field',
  minLength: {
    value: 8,
    message: 'Please specify input by patters nn-nn-nn',
  },
  maxLength: {
    value: 8,
    message: 'Please specify input by patters nn-nn-nn',
  },
};

export const accountNumberRules = {
  required: 'Please, specify Account Number field',
  minLength: {
    value: 8,
    message: 'Please specify input by 8 digits',
  },
  maxLength: {
    value: 8,
    message: 'Please specify input by 8 digits',
  },
};

export const ibanRules = {
  required: 'Please, specify Account Number field',
  minLength: {
    value: 15,
    message: 'Min length is 15',
  },
  maxLength: {
    value: 31,
    message: 'Max length is 31',
  },
};

export const bicRules = {
  required: 'Please, specify BIC field',
  pattern: {
    value: RegExp(/^[a-zA-Z0-9 ]{8}$|^[a-zA-Z0-9 ]{11}$/),
    message: 'Only alphanumeric symbols and spaces are allowed 8 or 11 symbols',
  },
  minLength: {
    value: 8,
    message: 'Only alphanumeric symbols and spaces are allowed 8 or 11 symbols',
  },
  maxLength: {
    value: 11,
    message: 'Only alphanumeric symbols and spaces are allowed 8 or 11 symbols',
  },
};

export const routingNumberRules = {
  minLength: {
    value: 9,
    message: 'Please specify input by 9 digits',
  },
  maxLength: {
    value: 9,
    message: 'Please specify input by 9 digits',
  },

};
export const bankNumberRules = {
  required: 'Please, specify Bank Number field',
  minLength: {
    value: 3,
    message: 'Min length is 3',
  },
};
