import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  ISellRequestState,
  TSellRequestParams,
  TSellRequestsResponse,
} from './sellRequests.types';
import { dispatchErrorMessage } from '../ui/ui';
import { handleDownloadFile } from '../../../utilities';
import { ResponseFile } from '../../apiTypes/shared/response';

const initialState: ISellRequestState = {
  result: [],
  isLoading: false,
  ...tablePaginationParams,
};
const createAsyncThunk = createEnhancedThunk('sellRequests');

export const getSellRequests = createAsyncThunk<TSellRequestsResponse['data'], PaginationType & TSellRequestParams>('getSellRequests',
  async ({
    page,
    perPage,
    params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TSellRequestsResponse>(`/api/sell_requests/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getSellRequestPdf = createAsyncThunk<void, number>('getSellRequestPdf',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await thunkAPI.extra.get<ResponseFile>(`/api/sell_requests/${id}/get_pdf/`);
      handleDownloadFile({ b64: data.file, filename: data.filename, mime_type: data.mime_type });
      return undefined;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getSellRequests.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getSellRequests.fulfilled, (state, { payload, meta }) => ({
    ...state,
    isLoading: false,
    result: payload.results,
    count: payload.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
  }));
  builder.addCase(getSellRequests.rejected, (state) => ({
    ...state,
    isLoading: false,
    result: [],
    count: 0,
  }));
});

export const selectSellRequestsData = createSelector(
  (state: RootState) => state.sellRequests,
  (sellRequests) => sellRequests,
);
