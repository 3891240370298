import { TPaymentMethod } from "./redux/ducks/paymentMethods/paymentMethods.types";

export const tablePaginationParams:{
  page:number
  perPage:number
  count:number
} = {
  page: 0,
  perPage: 100,
  count: 0,
};

export const paymentContent: Record<TPaymentMethod['method'], TPaymentMethod['details']> = {
  UK_BANK: {
    sort_code: 'Sort Code',
    account_number: 'Account Number',
  },
  INT_BANK: {
    iban: 'Iban',
    bic: 'Bic',
    bank_address: 'Bank Address',
    routing_number: 'Routing Number',
    currency: 'Preferred Currency',
  },
  PAYPAL: {
    email: 'Email',
  },
  WALLET: {

  },
  CARD: {

  },
};

export const loyaltyStatuses = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Wood',
    value: 'wood',
  },
  {
    text: 'Stone',
    value: 'stone',
  },
  {
    text: 'Bronze',
    value: 'bronze',
  },
  {
    text: 'Silver',
    value: 'silver',
  },
  {
    text: 'Gold',
    value: 'gold',
  },
  {
    text: 'Platinum',
    value: 'platinum',
  },
];

export const orderStatuses = [
  {
    text: 'All Statuses',
    value: '',
  },
  {
    text: 'Payment',
    value: 'Awaiting Payment',
  },
  {
    text: 'Accepted',
    value: 'Payment Accepted',
  },
  {
    text: 'Progress',
    value: 'Preparation in Progress',
  },
  {
    text: 'Shipped',
    value: 'Shipped',
  },
  {
    text: 'Collected',
    value: 'Collected',
  },
  {
    text: 'Cancelled',
    value: 'Cancelled',
  },
  {
    text: 'Refund',
    value: 'Refund',
  },
  {
    text: 'Error',
    value: 'Payment Error',
  },
  {
    text: 'Storage',
    value: 'Paid Collection(1 Month Free Storage',
  },
  {
    text: 'Transfe',
    value: 'Awaiting Bank Transfe',
  },
  {
    text: 'ITL',
    value: 'Awaiting Bank Transfer (ITL',
  },
  {
    text: 'Reques',
    value: 'Paid - Delayed Shipping Reques',
  },
  {
    text: 'Auctio',
    value: 'Relisted in Auctio',
  },
  {
    text: 'US',
    value: 'Shipped - US',
  },
  {
    text: 'TransferWis',
    value: 'Awaiting TransferWis',
  },
  {
    text: 'Paymen',
    value: 'Awaiting Late Paymen',
  },
  {
    text: 'Partiall',
    value: 'Shipped Partiall',
  },
  {
    text: 'Receive',
    value: 'Partial Payment Receive',
  },
  {
    text: 'Month',
    value: 'Paid - Stored (6 Month',
  },
  {
    text: 'Vali',
    value: 'Voucher - Vali',
  },
  {
    text: 'G',
    value: 'See G',
  },
  {
    text: 'Spli',
    value: 'Spli',
  },
];

export const paymentMethods = [
  {
    text: 'All Payment Methods',
    value: '',
  }, {
    text: 'Int Bank Transfer',
    value: 'Int Bank Transfer',

  }, {
    text: 'UK Bank Transfer',
    value: 'UK Bank Transfer',

  }, {
    text: 'Wallet',
    value: 'Wallet',

  }, {
    text: 'PayPal',
    value: 'PayPal',

  }, {
    text: 'Not selected',
    value: 'Not selected',
  },
];

export const vatOptions = [
  {
    text: 'All',
    value: '',
  }, {
    text: 'Yes',
    value: true,
  }, {
    text: 'No',
    value: false,
  },
];

export const paymentStatues = [
  {
    text: 'All Payment Statuses',
    value: '',
  }, {
    text: 'Ready for payment',
    value: 'Ready for payment',

  }, {
    text: 'Paid',
    value: 'Paid',

  }, {
    text: 'Not ready',
    value: 'Not ready',

  }, {
    text: 'Partially Paid',
    value: 'Partially Paid',

  },
];

export const paymentStatuesOrder = [
  {
    text: 'Payment accepted',
    value: 'payment_accepted',
  }, {
    text: 'Payment Waiting',
    value: 'payment_waiting',
  },
  {
    text: 'Refund',
    value: 'payment_refunded',
  },
];

export const paymentMethodsOrder = [
  {
    text: 'All Payment Statuses',
    value: '',
  },
  {
    text: 'Bank Transfer',
    value: 'bank_transfer',
  },
  {
    text: 'Card (Online)',
    value: 'card_online',
  },
  {
    text: 'Card (In-Store)',
    value: 'card_in_store',
  },
  {
    text: 'Cash',
    value: 'cash',
  },
  {
    text: 'Wallet',
    value: 'wallet',
  },
  {
    text: 'Split payment',
    value: 'split',
  },
];

export const paymentFieldsOrderInfo:any = {
  payment_accepted: 'Payment accepted',
  payment_waiting: 'Payment Waiting',
  payment_refunded: 'Refund',
  payment_failed: 'Payment Failed',
};

export const paymentMethodsOrderInfo:any = {
  bank_transfer: 'Bank Transfer',
  card_online: 'Card (Online)',
  card_in_store: 'Card (In-Store)',
  cash: 'Cash',
  wallet: 'Wallet',
  split: 'Split payment',
};

export default tablePaginationParams;
