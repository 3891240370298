import React, { FC, PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Theme } from '@mui/material';
import { Header } from '../../molecules/Header';
import { SideBar } from '../../molecules/SideBar';

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    margin: '0 auto',
  },
}));

const AppLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <SideBar />
      <Container className={classes.container} component="main" maxWidth={false}>
        {children}
      </Container>
    </>
  );
};
export default AppLayout;
