import { createReducer, createSelector } from '@reduxjs/toolkit';
import { OptionsType } from 'react-table';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { paymentContent, tablePaginationParams } from '../../../staticData';
import { PaginationType } from '../../apiTypes/shared/pagintaion';
import {
  IWalletCheckPayment,
  IWalletCheckPaymentBody,
  IWalletCustomersParams,
  IWalletState,
  IWalletTransaction,
  IWalletTransactionBody,
  IWalletTransactionParams,
  TPatchWalletTransactionResponse,
  TPostWalletTransactionsResponse,
  TWalletCheckPaymentResponse,
  TWalletCustomersResponse,
  TWalletPaymentDetailsResponse,
  TWalletTotalsResponse,
  TWalletTransactionReasonsResponse,
  TWalletTransactionsResponse,
  TWalletTransactionStatusesResponse,
  TWalletTransactionTypesResponse,
} from './wallet.types';
import { dispatchErrorMessage, errorMessageAction, successMessageAction } from '../ui/ui';
import readablePaymentMethod from '../paymentMethods/utils';

const initialErrorsState = {
  errors: null,
};

const initialState: IWalletState = {
  transactions: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  customers: {
    result: [],
    isLoading: false,
    ...tablePaginationParams,
    ...initialErrorsState,
  },
  totals: {
    isLoading: false,
    credits: "0",
    debits: "0",
    total: "0",
  },
  totalsDates: {
    date_from: undefined,
    date_to: undefined,
  },
  transactionDetails: {
    method: "WALLET",
    details: null,
    authorised_by: {},
  },
  types: [],
  reasons: [],
  statuses: [],
};
const createAsyncThunk = createEnhancedThunk('wallet');

export const getWalletTransactions = createAsyncThunk<TWalletTransactionsResponse['data'], PaginationType & IWalletTransactionParams>('getUserTransactions',
  async ({
    page,
    perPage,
    userId,
    params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TWalletTransactionsResponse>(`/api/admin_wallet/transactions/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          owner_id: userId,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWalletTotals = createAsyncThunk<TWalletTotalsResponse['data'], { userId?: number, date_from?: string, date_to?: string }>('getUserWalletTotals',
  async ({ userId, date_from, date_to }, thunkAPI) => {
    const params = {
      date_from,
      date_to,
    };
    try {
      const response = await thunkAPI.extra.get<TWalletTotalsResponse>(`/api/admin_wallet/financials_header/${userId ? `${userId}/` : ''}`, { params });
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWalletTransactionTypes = createAsyncThunk<TWalletTransactionTypesResponse['data'], void>('getWalletTransactionTypes',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TWalletTransactionTypesResponse>('/api/admin_wallet/transactions/type_list/');
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWalletTransactionReasons = createAsyncThunk<TWalletTransactionReasonsResponse['data'], void>('getWalletTransactionReasons',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TWalletTransactionReasonsResponse>('/api/admin_wallet/transactions/reason_list/');
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const getWalletTransactionStatuses = createAsyncThunk<TWalletTransactionStatusesResponse['data'], void>('getWalletTransactionStatuses',
  async (_, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TWalletTransactionStatusesResponse>('/api/admin_wallet/transactions/status_list/');
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(errorMessageAction("Error while loading statuses list"));
      return thunkAPI.rejectWithValue({});
    }
  });

export const postWalletTransaction = createAsyncThunk<IWalletTransaction, IWalletTransactionBody>('postWalletTransaction',
  async (body, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.post<TPostWalletTransactionsResponse>('/api/admin_wallet/transactions/', body);
      thunkAPI.dispatch(successMessageAction("Transaction have been created"));
      return res.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const patchWalletTransactionStatus = createAsyncThunk<IWalletTransaction[], { status: string, id: number }>('patchWalletTransactionStatus',
  async ({ status, id }, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.patch<TPatchWalletTransactionResponse>(`/api/admin_wallet/transactions/${id}/`, { status });
      thunkAPI.dispatch(successMessageAction("Transaction status has been changed"));
      const newTransaction = res.data.data;
      const state = thunkAPI.getState();
      const newTransactionsList = state.wallet.transactions.result.map((transaction) => (transaction.id === id ? newTransaction : transaction));
      return newTransactionsList;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getWalletPaymentDetails = createAsyncThunk<TWalletPaymentDetailsResponse['data'], { id: number }>('getWalletPaymentDetails',
  async ({ id }, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.get<TWalletPaymentDetailsResponse>(`/api/admin_wallet/transactions/${id}/payment_details/`);
      return res.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({});
    }
  });

export const getWalletCustomers = createAsyncThunk<TWalletCustomersResponse['data'], PaginationType & IWalletCustomersParams>('getWalletCustomers',
  async ({
    page,
    perPage,
    params,
  }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<TWalletCustomersResponse>(`/api/admin_wallet/customers/`, {
        params: {
          page: page + 1,
          page_size: perPage,
          ...params,
        },
      });
      return response.data.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export const checkUserWalletPayment = createAsyncThunk<IWalletCheckPayment, IWalletCheckPaymentBody>('getWalletPaymentDetails',
  async (body, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.post<TWalletCheckPaymentResponse>(`/api/check_wallet_payment_sum/`, body);
      return res.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({});
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getWalletTransactions.pending, (state) => ({
    ...state,
    transactions: {
      ...state.transactions,
      ...initialErrorsState,
      isLoading: true,
    },
  }));
  builder.addCase(getWalletTransactions.fulfilled, (state, { payload, meta }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));

  builder.addCase(getWalletTransactions.rejected, (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(postWalletTransaction.fulfilled, (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      ...initialErrorsState,
      result: [
        payload,
        ...state.transactions.result,
      ],
      count: state.transactions.count + 1,
      isLoading: false,
    },
  }));

  builder.addCase(getWalletTotals.pending, (state) => ({
    ...state,
    totals: {
      ...state.totals,
      isLoading: true,
    },
  }));
  builder.addCase(getWalletTotals.fulfilled, (state, { payload: totals, meta: { arg } }) => ({
    ...state,
    totals: {
      ...totals,
      isLoading: false,
    },
    totalsDates: {
      date_from: arg.date_from,
      date_to: arg.date_to,
    },
  }));
  builder.addCase(getWalletTotals.rejected, (state) => ({
    ...state,
    totals: {
      ...initialState.totals,
    },
  }));

  builder.addCase(getWalletTransactionTypes.fulfilled, (state, { payload: types }) => ({
    ...state,
    types,
  }));

  builder.addCase(getWalletTransactionReasons.fulfilled, (state, { payload: reasons }) => ({
    ...state,
    reasons,
  }));

  builder.addCase(getWalletTransactionStatuses.fulfilled, (state, { payload: statuses }) => ({
    ...state,
    statuses,
  }));

  builder.addCase(getWalletCustomers.pending, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      ...initialErrorsState,
      isLoading: true,
    },
  }));
  builder.addCase(getWalletCustomers.fulfilled, (state, { payload, meta }) => ({
    ...state,
    customers: {
      ...state.customers,
      ...initialErrorsState,
      result: payload.results,
      count: payload.count,
      page: meta.arg.page,
      perPage: meta.arg.perPage,
      isLoading: false,
    },
  }));
  builder.addCase(getWalletCustomers.rejected, (state, { payload }) => ({
    ...state,
    customers: {
      ...state.customers,
      isLoading: false,
      // @ts-ignore
      errors: payload.messages,
    },
  }));

  builder.addCase(patchWalletTransactionStatus.fulfilled, (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      ...initialErrorsState,
      result: payload,
      isLoading: false,
    },
  }));
  builder.addCase(getWalletPaymentDetails.fulfilled, (state, { payload }) => ({
    ...state,
    transactionDetails: payload,
  }));
});

export const selectTransactionsData = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.transactions,
);

export const selectWalletCustomersData = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.customers,
);

export const selectWalletTotals = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.totals,
);

export const selectWalletTotalsDates = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.totalsDates,
);

export const selectWalletTransactionsTypes = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.types.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);

export const selectWalletTransactionsReasons = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.reasons.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);

export const selectWalletTransactionsStatuses = createSelector(
  (state: RootState) => state.wallet,
  (wallet) => wallet.statuses.map((type) => ({ text: type.value, value: type.key })) as OptionsType[],
);

export const selectWalletPaymentDetails = createSelector(
  (state: RootState) => state.wallet.transactionDetails,
  (payment) => payment && [
    {
      title: 'Payment Method',
      name: readablePaymentMethod[payment.method],
    },
    ...Object.entries({
      ...paymentContent?.[payment.method],
    }).map((item:any) => ({
      title: item[1],
      name: payment?.details?.[item[0]],
    })),
    // @ts-ignore
    { title: "Authorized by", name: `${payment.authorised_by?.first_name} ${payment.authorised_by?.last_name}` },
  ],
);
