import { createReducer, createSelector } from '@reduxjs/toolkit';
import createEnhancedThunk from '../../enhancedAsyncThunkCreator';
import { RootState } from '../../rootReducer';
import { tablePaginationParams } from '../../../staticData';
import { dispatchErrorMessage } from '../ui/ui';

const initialErrorsState = {
  errors: null,
};

const initialState: any = {
  result: [],
  isLoading: false,
  ...tablePaginationParams,
  ...initialErrorsState,
};
const createAsyncThunk = createEnhancedThunk('usersSellerSheets');

export const getUsersSellerSheetsAction = createAsyncThunk<any, any>('getUsersSellerSheetsAction',
  async ({ id, page, perPage }, thunkAPI) => {
    try {
      const response = await thunkAPI.extra.get<any, any>(`/api/users/${id}/seller_sheets/`, {
        params: {
          page: page + 1,
          page_size: perPage,
        },
      });
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(dispatchErrorMessage(err));
      return thunkAPI.rejectWithValue({
        messages: err?.response?.data?.data ?? { error: ['Server error'] },
      });
    }
  });

export default createReducer(initialState, (builder) => {
  builder.addCase(getUsersSellerSheetsAction.fulfilled, (state, { payload, meta }) => ({
    ...state,
    ...initialErrorsState,
    result: payload.data.results,
    count: payload.data.count,
    page: meta.arg.page,
    perPage: meta.arg.perPage,
    isLoading: false,
  }));
  builder.addCase(getUsersSellerSheetsAction.pending, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getUsersSellerSheetsAction.rejected, (state, action) => ({
    ...state,
    ...initialErrorsState,
    // @ts-ignore
    errors: action?.payload?.messages,
    isLoading: false,
  }));
});

export const selectLoadingStatus = createSelector(
  (state: RootState) => state.usersSellerSheets.isLoading,
  (id) => id,
);

export const selectUsersSellerSheets = createSelector(
  (state: RootState) => state.usersSellerSheets.result,
  (result) => result,
);

export const selectUsersSellerSheetsCount = createSelector(
  (state: RootState) => state.usersSellerSheets.count,
  (count) => count,
);

export const selectUsersSellerPage = createSelector(
  (state: RootState) => state.usersSellerSheets.page,
  (page) => page,
);

export const selectUsersSellerPerPage = createSelector(
  (state: RootState) => state.usersSellerSheets.perPage,
  (page) => page,
);
